var actions = {
    nuxtServerInit: function (store, _a) {
        var env = _a.env;
    },
    loadLoginAuto: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        var userInfo = this.$Cache().getInfo();
        return this.$apiAjax({
            apiName: "user/SysUser/LoginAuto",
            data: { SystemType: 1, OID: userInfo.CompanyID }
        });
    },
    loadMenus: function (_a, data) {
        var commit = _a.commit;
        return this.$apiAjax({
            apiName: "user/SysUser/GetMenus",
            data: { SystemType: 1 }
        });
    },
    logout: function (_a, data) {
        var commit = _a.commit;
        return this.$axios.$post('/api/logout').then(function (response) {
            if (response.State === 1) {
                commit('LOGOUT');
            }
            else {
                return response;
            }
        });
    },
    getOrganizations: function () {
        return this.$apiAjax({
            apiName: "user/SysUser/GetAllJoinOrganizations"
        });
    },
    selectOrganization: function (_a, data) {
        var commit = _a.commit;
        return this.$apiAjax({
            apiName: "user/SysUser/SelectOrganization",
            data: data
        });
    },
    upload: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        var formData = new FormData();
        formData.append('file', data.file);
        return this.$apiAjax({
            apiName: data.url,
            data: formData,
            requestMethod: 'form'
        });
    },
    uploadImages: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: data.url,
            data: data.data,
            requestMethod: 'form'
        });
    }
};
export default actions;
