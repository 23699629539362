var state = function () { return ({
    listParams: {
        OID: '',
        SupplierTypeCode: -1,
        MinArrearsAmount: null,
        MaxArrearsAmount: null,
        KeyValue: ''
    }
}); };
var getters = {
    getterParams: function (state) {
        Object.assign(state.listParams, { OID: null });
        return state.listParams;
    }
};
var mutations = {
    UPDATEPARAMS: function (state, data) {
        Object.assign(state.listParams, data);
    }
};
var actions = {
    getList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Bills/SupplierBillList",
            data: Data
        });
    },
    detail: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Bills/SupplierInfo",
            data: Data
        });
    },
    SupplierBillPayableList: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Bills/SupplierBillPayableList",
            data: data
        });
    },
    SupplierBillRecordList: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Bills/SupplierBillRecordList",
            data: data
        });
    },
    SupplierBillBalancingList: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Bills/SupplierBillBalancingList",
            data: data
        });
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
