var state = function () { return ({}); };
var mutations = {};
var actions = {
    FinancialSubmit: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'Financial/Project/submit',
            data: data
        });
    },
    Current: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'Financial/Project/current',
            data: data
        });
    },
    GetList: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'Financial/Project/GetList',
            data: data
        });
    },
    Credit: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'Financial/Tenant/Credit',
            data: data
        });
    },
    CreditDetail: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'Financial/Tenant/CreditDetail',
            data: data
        });
    },
    Logs: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'Financial/Project/logs',
            data: data
        });
    },
    GenerateReports: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'Financial/Project/GenerateReports',
            data: data
        });
    },
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
