import companydepart from "~/store/modules/common/company-depart";
import tagmanage from "~/store/modules/common/tag-manage";
import selectcarmodel from "~/store/modules/common/select-carmodel";
import enums from "~/store/modules/common/enums";
import importfile from "~/store/modules/common/import-file";
import exportfile from "~/store/modules/common/export-file";
import common from "~/store/modules/common/common";
var state = function () { return ({}); };
var mutations = {};
var actions = {};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {
        companydepart: companydepart,
        selectcarmodel: selectcarmodel,
        enums: enums,
        tagmanage: tagmanage,
        importfile: importfile,
        exportfile: exportfile,
        common: common
    }
};
