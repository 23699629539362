var state = function () { return ({
    PaymentType: [],
    UseProperties: [],
    UseTypes: [],
    OwnerTypes: [],
    PlateStatus: [],
    VehicleTypes: [],
    VehicleStatus: [],
    VehicleTagStatus: [],
    VehicleCurrentTagStatus: [],
    VehicleNatureTypes: [],
    SettleStatus: [],
    SupplierType: [],
    AccidentByType: [],
    FeeType: [],
}); };
var mutations = {
    setPaymentType: function (state, Data) {
        state.PaymentType = Data;
    },
    setUseProperties: function (state, Data) {
        state.UseProperties = Data;
    },
    setUseTypes: function (state, Data) {
        state.UseTypes = Data;
    },
    setOwnerTypes: function (state, Data) {
        state.OwnerTypes = Data;
    },
    setPlateStatus: function (state, Data) {
        state.PlateStatus = Data;
    },
    setVehicleTypes: function (state, Data) {
        state.VehicleTypes = Data;
    },
    setVehicleStatus: function (state, Data) {
        state.VehicleStatus = Data;
    },
    setVehicleTagStatus: function (state, Data) {
        state.VehicleTagStatus = Data;
    },
    setVehicleCurrentTagStatus: function (state, Data) {
        state.VehicleCurrentTagStatus = Data;
    },
    setVehicleNatureTypes: function (state, Data) {
        state.VehicleNatureTypes = Data;
    },
    setBillingState: function (state, Data) {
        state.SettleStatus = Data;
    },
    setSupplierType: function (state, Data) {
        state.SupplierType = Data;
    },
    setAccidentByType: function (state, Data) {
        state.AccidentByType = Data;
    },
    setFeeType: function (state, Data) {
        state.FeeType = Data;
    },
};
var actions = {
    getPayments: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        if (!state.PaymentType.length) {
            return this.$apiAjax({
                apiName: "saas/Bills/GetPayments",
                data: Data,
            }).then(function (res) {
                var result = res.data;
                if (result.State === 1) {
                    commit("setPaymentType", result.Data);
                }
            });
        }
    },
    getUseProperties: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        if (!state.UseProperties.length) {
            return this.$apiAjax({
                apiName: "saas/Plate/UseProperties",
                data: Data,
            }).then(function (res) {
                var result = res.data;
                if (result.State === 1) {
                    commit("setUseProperties", result.Data);
                }
            });
        }
    },
    getUseTypes: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        if (!state.UseTypes.length) {
            return this.$apiAjax({
                apiName: "saas/Plate/UseTypes",
                data: Data,
            }).then(function (res) {
                var result = res.data;
                if (result.State === 1) {
                    commit("setUseTypes", result.Data);
                }
            });
        }
    },
    getOwnerTypes: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        if (!state.OwnerTypes.length) {
            return this.$apiAjax({
                apiName: "saas/Plate/OwnerTypes",
                data: Data,
            }).then(function (res) {
                var result = res.data;
                if (result.State === 1) {
                    commit("setOwnerTypes", result.Data);
                }
            });
        }
    },
    getPlateStatus: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        if (!state.PlateStatus.length) {
            return this.$apiAjax({
                apiName: "saas/Plate/PlateStatus",
                data: Data,
            }).then(function (res) {
                var result = res.data;
                if (result.State === 1) {
                    commit("setPlateStatus", result.Data);
                }
            });
        }
    },
    getVehicleTypes: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        if (!state.VehicleTypes.length) {
            return this.$apiAjax({
                apiName: "saas/Vehicle/Types",
                data: Data,
            }).then(function (res) {
                var result = res.data;
                if (result.State === 1) {
                    commit("setVehicleTypes", result.Data);
                }
            });
        }
    },
    getVehicleStatus: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        if (!state.VehicleStatus.length) {
            return this.$apiAjax({
                apiName: "saas/Vehicle/Status",
                data: Data,
            }).then(function (res) {
                var result = res.data;
                if (result.State === 1) {
                    commit("setVehicleStatus", [{ Value: -1, Display: "全部" }].concat(result.Data));
                }
            });
        }
    },
    getVehicleTagStatus: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        if (!state.VehicleTagStatus.length) {
            return this.$apiAjax({
                apiName: "saas/Vehicle/TagStatus",
                data: Data,
            }).then(function (res) {
                var result = res.data;
                if (result.State === 1) {
                    commit("setVehicleTagStatus", result.Data);
                }
            });
        }
    },
    getVehicleCurrentTagStatus: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        if (!state.VehicleCurrentTagStatus.length) {
            return this.$apiAjax({
                apiName: "saas/Tags/GetCurrentList",
                data: Data,
            }).then(function (res) {
                var result = res.data;
                if (result.State === 1) {
                    commit("setVehicleCurrentTagStatus", result.Data);
                }
            });
        }
    },
    getVehicleNatureTypes: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        if (!state.VehicleNatureTypes.length) {
            return this.$apiAjax({
                apiName: "saas/Vehicle/NatureTypes",
                data: Data,
            }).then(function (res) {
                var result = res.data;
                if (result.State === 1) {
                    commit("setVehicleNatureTypes", result.Data);
                }
            });
        }
    },
    BillingState: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        if (!state.SettleStatus.length) {
            return this.$apiAjax({
                apiName: "traffic/Common/BillingState",
                data: Data,
            }).then(function (res) {
                var result = res.data;
                if (result.State === 1) {
                    commit("setBillingState", result.Data);
                }
            });
        }
    },
    SupplierTypes: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        if (!state.SupplierType.length) {
            return this.$apiAjax({
                apiName: "traffic/Supplier/SupplierTypes",
                data: Data,
            }).then(function (res) {
                var result = res.data;
                if (result.State === 1) {
                    commit("setSupplierType", result.Data);
                }
            });
        }
    },
    FeeTypes: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        if (!state.SupplierType.length) {
            return this.$apiAjax({
                apiName: "traffic/TrafficBill/FeeTypes",
                data: Data,
            }).then(function (res) {
                var result = res.data;
                if (result.State === 1) {
                    commit("setFeeType", result.Data);
                }
            });
        }
    },
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
