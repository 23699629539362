var state = function () { return ({}); };
var mutations = {};
var actions = {
    getBillAccountStatics: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/statistic/getBillAccountStatics',
            data: aData
        });
    },
    getAccountStatisticDetail: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/statistic/getAccountStatisticDetail',
            data: aData
        });
    },
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
