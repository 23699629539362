var state = function () { return ({}); };
var mutations = {};
var actions = {
    createSupplier: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'traffic/Supplier/create',
            data: aData
        });
    },
    editorSupplier: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'traffic/Supplier/Editor',
            data: aData
        });
    },
    deleteSupplier: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'traffic/Supplier/Delete',
            data: aData
        });
    },
    GetSupplierList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'traffic/Supplier/GetSupplierList',
            data: aData
        });
    },
    GetSupplierDetails: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'traffic/Supplier/GetSupplierDetails',
            data: aData
        });
    },
    GetSupplierScreenList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'traffic/Supplier/GetSupplierScreenList',
            data: aData
        });
    },
    AddSupplierType: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'traffic/Supplier/AddSupplierType',
            data: aData
        });
    },
    GetSupplierTypeList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'traffic/Supplier/GetSupplierTypeList',
            data: aData
        });
    },
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
