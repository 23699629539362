var state = {};
var getters = {};
var mutations = {};
var actions = {
    getFlowList: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Flows/GetFlowList",
            data: data
        });
    },
    addOrUpdateFlow: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Flows/AddOrUpdateFlow",
            data: data
        });
    },
    getSysUsers: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Flows/GetSysUsers",
            data: data
        });
    },
    getRoles: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Flows/GetRoles",
            data: data
        });
    },
    getFlowItems: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Flows/GetFlowItems",
            data: data
        });
    },
    getRoleList: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "user/Role/SimpleList",
            data: data
        });
    }
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
