import "core-js/modules/es6.string.iterator";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import _typeof from "@babel/runtime/helpers/typeof";
import "core-js/modules/es6.function.name";
import "regenerator-runtime/runtime";
import "core-js/modules/es6.regexp.match";
import _asyncToGenerator from "@babel/runtime/helpers/asyncToGenerator";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.promise";
import "core-js/modules/es7.promise.finally";
import Vue from 'vue';
import middleware from './middleware';
import { applyAsyncData, sanitizeComponent, resolveRouteComponents, getMatchedComponents, getMatchedComponentsInstances, flatMapComponents, setContext, middlewareSeries, promisify, getLocation, compile, getQueryDiff, globalHandleError } from './utils';
import { createApp, NuxtError } from './index';
var noopData = function noopData() {
    return {};
};
var noopFetch = function noopFetch() { };
var _lastPaths = [];
var app;
var router;
var store;
var NUXT = window.__NUXT__ || {};
Object.assign(Vue.config, {
    "performance": true,
    "errorHandler": function errorHandler(err, vm, info) {
        console.error(err, vm, info);
    },
    "silent": true
});
createApp().then(mountApp).catch(function (err) {
    console.error('[nuxt] Error while initializing app', err);
});
function componentOption(component, key) {
    if (!component || !component.options || !component.options[key]) {
        return {};
    }
    var option = component.options[key];
    if (typeof option === 'function') {
        for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
            args[_key - 2] = arguments[_key];
        }
        return option.apply(void 0, args);
    }
    return option;
}
function mapTransitions(Components, to, from) {
    var componentTransitions = function componentTransitions(component) {
        var transition = componentOption(component, 'transition', to, from) || {};
        return typeof transition === 'string' ? {
            name: transition
        } : transition;
    };
    return Components.map(function (Component) {
        var transitions = Object.assign({}, componentTransitions(Component));
        if (from && from.matched.length && from.matched[0].components.default) {
            var fromTransitions = componentTransitions(from.matched[0].components.default);
            Object.keys(fromTransitions).filter(function (key) {
                return fromTransitions[key] && key.toLowerCase().includes('leave');
            }).forEach(function (key) {
                transitions[key] = fromTransitions[key];
            });
        }
        return transitions;
    });
}
function loadAsyncComponents(_x, _x2, _x3) {
    return _loadAsyncComponents.apply(this, arguments);
}
function _loadAsyncComponents() {
    _loadAsyncComponents = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(to, from, next) {
        var _this3 = this;
        var Components, startLoader;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
            while (1) {
                switch (_context2.prev = _context2.next) {
                    case 0:
                        this._pathChanged = !!app.nuxt.err || from.path !== to.path;
                        this._queryChanged = JSON.stringify(to.query) !== JSON.stringify(from.query);
                        this._diffQuery = this._queryChanged ? getQueryDiff(to.query, from.query) : [];
                        if (this._pathChanged && this.$loading.start && !this.$loading.manual) {
                            this.$loading.start();
                        }
                        _context2.prev = 4;
                        _context2.next = 7;
                        return resolveRouteComponents(to);
                    case 7:
                        Components = _context2.sent;
                        if (!this._pathChanged && this._queryChanged) {
                            startLoader = Components.some(function (Component) {
                                var watchQuery = Component.options.watchQuery;
                                if (watchQuery === true)
                                    return true;
                                if (Array.isArray(watchQuery)) {
                                    return watchQuery.some(function (key) {
                                        return _this3._diffQuery[key];
                                    });
                                }
                                return false;
                            });
                            if (startLoader && this.$loading.start && !this.$loading.manual) {
                                this.$loading.start();
                            }
                        }
                        next();
                        _context2.next = 17;
                        break;
                    case 12:
                        _context2.prev = 12;
                        _context2.t0 = _context2["catch"](4);
                        this.error(_context2.t0);
                        this.$nuxt.$emit('routeChanged', to, from, error);
                        next(false);
                    case 17:
                    case "end":
                        return _context2.stop();
                }
            }
        }, _callee2, this, [[4, 12]]);
    }));
    return _loadAsyncComponents.apply(this, arguments);
}
function applySSRData(Component, ssrData) {
    if (NUXT.serverRendered && ssrData) {
        applyAsyncData(Component, ssrData);
    }
    Component._Ctor = Component;
    return Component;
}
function resolveComponents(router) {
    var path = getLocation(router.options.base, router.options.mode);
    return flatMapComponents(router.match(path), function () {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(Component, _, match, key, index) {
            var _Component;
            return regeneratorRuntime.wrap(function _callee$(_context) {
                while (1) {
                    switch (_context.prev = _context.next) {
                        case 0:
                            if (!(typeof Component === 'function' && !Component.options)) {
                                _context.next = 4;
                                break;
                            }
                            _context.next = 3;
                            return Component();
                        case 3:
                            Component = _context.sent;
                        case 4:
                            _Component = applySSRData(sanitizeComponent(Component), NUXT.data ? NUXT.data[index] : null);
                            match.components[key] = _Component;
                            return _context.abrupt("return", _Component);
                        case 7:
                        case "end":
                            return _context.stop();
                    }
                }
            }, _callee, this);
        }));
        return function (_x4, _x5, _x6, _x7, _x8) {
            return _ref.apply(this, arguments);
        };
    }());
}
function callMiddleware(Components, context, layout) {
    var _this = this;
    var midd = [];
    var unknownMiddleware = false;
    if (typeof layout !== 'undefined') {
        midd = [];
        if (layout.middleware) {
            midd = midd.concat(layout.middleware);
        }
        Components.forEach(function (Component) {
            if (Component.options.middleware) {
                midd = midd.concat(Component.options.middleware);
            }
        });
    }
    midd = midd.map(function (name) {
        if (typeof name === 'function')
            return name;
        if (typeof middleware[name] !== 'function') {
            unknownMiddleware = true;
            _this.error({
                statusCode: 500,
                message: 'Unknown middleware ' + name
            });
        }
        return middleware[name];
    });
    if (unknownMiddleware)
        return;
    return middlewareSeries(midd, context);
}
function render(_x9, _x10, _x11) {
    return _render.apply(this, arguments);
}
function _render() {
    _render = _asyncToGenerator(regeneratorRuntime.mark(function _callee3(to, from, next) {
        var _this4 = this;
        var fromMatches, nextCalled, _next, matches, Components, layout, _layout, isValid, _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, Component, _error, _layout2;
        return regeneratorRuntime.wrap(function _callee3$(_context3) {
            while (1) {
                switch (_context3.prev = _context3.next) {
                    case 0:
                        if (!(this._pathChanged === false && this._queryChanged === false)) {
                            _context3.next = 2;
                            break;
                        }
                        return _context3.abrupt("return", next());
                    case 2:
                        if (to === from)
                            _lastPaths = [];
                        else {
                            fromMatches = [];
                            _lastPaths = getMatchedComponents(from, fromMatches).map(function (Component, i) {
                                return compile(from.matched[fromMatches[i]].path)(from.params);
                            });
                        }
                        nextCalled = false;
                        _next = function _next(path) {
                            if (from.path === path.path && _this4.$loading.finish) {
                                _this4.$loading.finish();
                            }
                            if (from.path !== path.path && _this4.$loading.pause) {
                                _this4.$loading.pause();
                            }
                            if (nextCalled)
                                return;
                            nextCalled = true;
                            next(path);
                        };
                        _context3.next = 7;
                        return setContext(app, {
                            route: to,
                            from: from,
                            next: _next.bind(this)
                        });
                    case 7:
                        this._dateLastError = app.nuxt.dateErr;
                        this._hadError = !!app.nuxt.err;
                        matches = [];
                        Components = getMatchedComponents(to, matches);
                        if (Components.length) {
                            _context3.next = 25;
                            break;
                        }
                        _context3.next = 14;
                        return callMiddleware.call(this, Components, app.context);
                    case 14:
                        if (!nextCalled) {
                            _context3.next = 16;
                            break;
                        }
                        return _context3.abrupt("return");
                    case 16:
                        _context3.next = 18;
                        return this.loadLayout(typeof NuxtError.layout === 'function' ? NuxtError.layout(app.context) : NuxtError.layout);
                    case 18:
                        layout = _context3.sent;
                        _context3.next = 21;
                        return callMiddleware.call(this, Components, app.context, layout);
                    case 21:
                        if (!nextCalled) {
                            _context3.next = 23;
                            break;
                        }
                        return _context3.abrupt("return");
                    case 23:
                        app.context.error({
                            statusCode: 404,
                            message: "This page could not be found"
                        });
                        return _context3.abrupt("return", next());
                    case 25:
                        Components.forEach(function (Component) {
                            if (Component._Ctor && Component._Ctor.options) {
                                Component.options.asyncData = Component._Ctor.options.asyncData;
                                Component.options.fetch = Component._Ctor.options.fetch;
                            }
                        });
                        this.setTransitions(mapTransitions(Components, to, from));
                        _context3.prev = 27;
                        _context3.next = 30;
                        return callMiddleware.call(this, Components, app.context);
                    case 30:
                        if (!nextCalled) {
                            _context3.next = 32;
                            break;
                        }
                        return _context3.abrupt("return");
                    case 32:
                        if (!app.context._errored) {
                            _context3.next = 34;
                            break;
                        }
                        return _context3.abrupt("return", next());
                    case 34:
                        _layout = Components[0].options.layout;
                        if (typeof _layout === 'function') {
                            _layout = _layout(app.context);
                        }
                        _context3.next = 38;
                        return this.loadLayout(_layout);
                    case 38:
                        _layout = _context3.sent;
                        _context3.next = 41;
                        return callMiddleware.call(this, Components, app.context, _layout);
                    case 41:
                        if (!nextCalled) {
                            _context3.next = 43;
                            break;
                        }
                        return _context3.abrupt("return");
                    case 43:
                        if (!app.context._errored) {
                            _context3.next = 45;
                            break;
                        }
                        return _context3.abrupt("return", next());
                    case 45:
                        isValid = true;
                        _context3.prev = 46;
                        _iteratorNormalCompletion = true;
                        _didIteratorError = false;
                        _iteratorError = undefined;
                        _context3.prev = 50;
                        _iterator = Components[Symbol.iterator]();
                    case 52:
                        if (_iteratorNormalCompletion = (_step = _iterator.next()).done) {
                            _context3.next = 64;
                            break;
                        }
                        Component = _step.value;
                        if (!(typeof Component.options.validate !== 'function')) {
                            _context3.next = 56;
                            break;
                        }
                        return _context3.abrupt("continue", 61);
                    case 56:
                        _context3.next = 58;
                        return Component.options.validate(app.context);
                    case 58:
                        isValid = _context3.sent;
                        if (isValid) {
                            _context3.next = 61;
                            break;
                        }
                        return _context3.abrupt("break", 64);
                    case 61:
                        _iteratorNormalCompletion = true;
                        _context3.next = 52;
                        break;
                    case 64:
                        _context3.next = 70;
                        break;
                    case 66:
                        _context3.prev = 66;
                        _context3.t0 = _context3["catch"](50);
                        _didIteratorError = true;
                        _iteratorError = _context3.t0;
                    case 70:
                        _context3.prev = 70;
                        _context3.prev = 71;
                        if (!_iteratorNormalCompletion && _iterator.return != null) {
                            _iterator.return();
                        }
                    case 73:
                        _context3.prev = 73;
                        if (!_didIteratorError) {
                            _context3.next = 76;
                            break;
                        }
                        throw _iteratorError;
                    case 76:
                        return _context3.finish(73);
                    case 77:
                        return _context3.finish(70);
                    case 78:
                        _context3.next = 84;
                        break;
                    case 80:
                        _context3.prev = 80;
                        _context3.t1 = _context3["catch"](46);
                        this.error({
                            statusCode: _context3.t1.statusCode || '500',
                            message: _context3.t1.message
                        });
                        return _context3.abrupt("return", next());
                    case 84:
                        if (isValid) {
                            _context3.next = 87;
                            break;
                        }
                        this.error({
                            statusCode: 404,
                            message: "This page could not be found"
                        });
                        return _context3.abrupt("return", next());
                    case 87:
                        _context3.next = 89;
                        return Promise.all(Components.map(function (Component, i) {
                            Component._path = compile(to.matched[matches[i]].path)(to.params);
                            Component._dataRefresh = false;
                            if (_this4._pathChanged && _this4._queryChanged || Component._path !== _lastPaths[i]) {
                                Component._dataRefresh = true;
                            }
                            else if (!_this4._pathChanged && _this4._queryChanged) {
                                var watchQuery = Component.options.watchQuery;
                                if (watchQuery === true) {
                                    Component._dataRefresh = true;
                                }
                                else if (Array.isArray(watchQuery)) {
                                    Component._dataRefresh = watchQuery.some(function (key) {
                                        return _this4._diffQuery[key];
                                    });
                                }
                            }
                            if (!_this4._hadError && _this4._isMounted && !Component._dataRefresh) {
                                return Promise.resolve();
                            }
                            var promises = [];
                            var hasAsyncData = Component.options.asyncData && typeof Component.options.asyncData === 'function';
                            var hasFetch = !!Component.options.fetch;
                            var loadingIncrease = hasAsyncData && hasFetch ? 30 : 45;
                            if (hasAsyncData) {
                                var promise = promisify(Component.options.asyncData, app.context).then(function (asyncDataResult) {
                                    applyAsyncData(Component, asyncDataResult);
                                    if (_this4.$loading.increase) {
                                        _this4.$loading.increase(loadingIncrease);
                                    }
                                });
                                promises.push(promise);
                            }
                            _this4.$loading.manual = Component.options.loading === false;
                            if (hasFetch) {
                                var p = Component.options.fetch(app.context);
                                if (!p || !(p instanceof Promise) && typeof p.then !== 'function') {
                                    p = Promise.resolve(p);
                                }
                                p.then(function (fetchResult) {
                                    if (_this4.$loading.increase) {
                                        _this4.$loading.increase(loadingIncrease);
                                    }
                                });
                                promises.push(p);
                            }
                            return Promise.all(promises);
                        }));
                    case 89:
                        if (!nextCalled) {
                            if (this.$loading.finish && !this.$loading.manual) {
                                this.$loading.finish();
                            }
                            next();
                        }
                        _context3.next = 106;
                        break;
                    case 92:
                        _context3.prev = 92;
                        _context3.t2 = _context3["catch"](27);
                        _error = _context3.t2 || {};
                        if (!(_error.message === 'ERR_REDIRECT')) {
                            _context3.next = 97;
                            break;
                        }
                        return _context3.abrupt("return", this.$nuxt.$emit('routeChanged', to, from, _error));
                    case 97:
                        _lastPaths = [];
                        globalHandleError(_error);
                        _layout2 = NuxtError.layout;
                        if (typeof _layout2 === 'function') {
                            _layout2 = _layout2(app.context);
                        }
                        _context3.next = 103;
                        return this.loadLayout(_layout2);
                    case 103:
                        this.error(_error);
                        this.$nuxt.$emit('routeChanged', to, from, _error);
                        next(false);
                    case 106:
                    case "end":
                        return _context3.stop();
                }
            }
        }, _callee3, this, [[27, 92], [46, 80], [50, 66, 70, 78], [71, , 73, 77]]);
    }));
    return _render.apply(this, arguments);
}
function normalizeComponents(to, ___) {
    flatMapComponents(to, function (Component, _, match, key) {
        if (_typeof(Component) === 'object' && !Component.options) {
            Component = Vue.extend(Component);
            Component._Ctor = Component;
            match.components[key] = Component;
        }
        return Component;
    });
}
function showNextPage(to) {
    if (this._hadError && this._dateLastError === this.$options.nuxt.dateErr) {
        this.error();
    }
    var layout = this.$options.nuxt.err ? NuxtError.layout : to.matched[0].components.default.options.layout;
    if (typeof layout === 'function') {
        layout = layout(app.context);
    }
    this.setLayout(layout);
}
function fixPrepatch(to, ___) {
    var _this2 = this;
    if (this._pathChanged === false && this._queryChanged === false)
        return;
    Vue.nextTick(function () {
        var matches = [];
        var instances = getMatchedComponentsInstances(to, matches);
        var Components = getMatchedComponents(to, matches);
        instances.forEach(function (instance, i) {
            if (!instance)
                return;
            if (instance.constructor._dataRefresh && Components[i] === instance.constructor && typeof instance.constructor.options.data === 'function') {
                var newData = instance.constructor.options.data.call(instance);
                for (var key in newData) {
                    Vue.set(instance.$data, key, newData[key]);
                }
            }
        });
        showNextPage.call(_this2, to);
    });
}
function nuxtReady(_app) {
    window.onNuxtReadyCbs.forEach(function (cb) {
        if (typeof cb === 'function') {
            cb(_app);
        }
    });
    if (typeof window._onNuxtLoaded === 'function') {
        window._onNuxtLoaded(_app);
    }
    router.afterEach(function (to, from) {
        Vue.nextTick(function () {
            return _app.$nuxt.$emit('routeChanged', to, from);
        });
    });
}
function mountApp(_x12) {
    return _mountApp.apply(this, arguments);
}
function _mountApp() {
    _mountApp = _asyncToGenerator(regeneratorRuntime.mark(function _callee4(__app) {
        var Components, _app, layout, mount;
        return regeneratorRuntime.wrap(function _callee4$(_context4) {
            while (1) {
                switch (_context4.prev = _context4.next) {
                    case 0:
                        app = __app.app;
                        router = __app.router;
                        store = __app.store;
                        _context4.next = 5;
                        return Promise.all(resolveComponents(router));
                    case 5:
                        Components = _context4.sent;
                        _app = new Vue(app);
                        layout = NUXT.layout || 'default';
                        _context4.next = 10;
                        return _app.loadLayout(layout);
                    case 10:
                        _app.setLayout(layout);
                        mount = function mount() {
                            _app.$mount('#__nuxt');
                            Vue.nextTick(function () {
                                nuxtReady(_app);
                            });
                        };
                        _app.setTransitions = _app.$options.nuxt.setTransitions.bind(_app);
                        if (Components.length) {
                            _app.setTransitions(mapTransitions(Components, router.currentRoute));
                            _lastPaths = router.currentRoute.matched.map(function (route) {
                                return compile(route.path)(router.currentRoute.params);
                            });
                        }
                        _app.$loading = {};
                        if (NUXT.error)
                            _app.error(NUXT.error);
                        router.beforeEach(loadAsyncComponents.bind(_app));
                        router.beforeEach(render.bind(_app));
                        router.afterEach(normalizeComponents);
                        router.afterEach(fixPrepatch.bind(_app));
                        if (!NUXT.serverRendered) {
                            _context4.next = 23;
                            break;
                        }
                        mount();
                        return _context4.abrupt("return");
                    case 23:
                        render.call(_app, router.currentRoute, router.currentRoute, function (path) {
                            if (!path) {
                                normalizeComponents(router.currentRoute, router.currentRoute);
                                showNextPage.call(_app, router.currentRoute);
                                mount();
                                return;
                            }
                            router.push(path, function () {
                                return mount();
                            }, function (err) {
                                if (!err)
                                    return mount();
                                console.error(err);
                            });
                        });
                    case 24:
                    case "end":
                        return _context4.stop();
                }
            }
        }, _callee4, this);
    }));
    return _mountApp.apply(this, arguments);
}
