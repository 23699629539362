var state = function () { return ({}); };
var mutations = {};
var actions = {
    getTaskList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/task/getlist',
            data: aData
        });
    },
    getType: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/task/gettype',
            data: aData
        });
    },
    followTask: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/task/followTask',
            data: aData
        });
    },
    taskView: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/task/taskView',
            data: aData
        });
    },
    taskHistoryAndRecord: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/task/taskHistoryAndRecord',
            data: aData
        });
    },
    getRecords: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/task/getRecords',
            data: aData
        });
    },
    insertFollowRecord: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/task/insertFollowRecord',
            data: aData
        });
    },
    removeTask: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/task/removeTask',
            data: aData
        });
    }
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
