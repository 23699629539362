var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Vue, Component, Getter } from "nuxt-property-decorator";
import { Action } from "vuex-class";
import Logo from "~/components/swt/layout/header/Logo.vue";
import Navbar from "~/components/swt/layout/header/Navbar.vue";
import Screenfull from "~/components/base/screenfull/index.vue";
import Theme from "~/components/base/theme/index.vue";
var default_1 = (function (_super) {
    __extends(default_1, _super);
    function default_1() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.gpsData = [
            {
                url: "http://219.147.23.237:8000/workspace/",
                name: "鲁诺GPS",
            },
        ];
        _this.list = [];
        _this.selectId = "";
        _this.oGetInfo = _this.$Cache().getInfo();
        _this.OID = _this.oGetInfo.OID;
        _this.organizationName = _this.oGetInfo.OrganizationShorterName || "";
        return _this;
    }
    default_1.prototype.mounted = function () {
        var dispose = this.$Cache().getDispose() || {};
        if (!dispose.HeadImgUrl) {
            this.getHeadImg();
        }
        else {
            this.$store.commit("SETCONFIG", { HeadImgUrl: dispose.HeadImgUrl });
        }
        this.getOrganizations();
        if (this.oGetInfo.OID > -1) {
            this.getUserInfo();
        }
    };
    default_1.prototype.handleOrganization = function (e) {
        if (e.OID !== this.oGetInfo.OID) {
            var data = {
                OrganizationName: e.OrganizationName,
                OrganizationShorterName: e.OrganizationShorterName,
                RoleName: e.RoleName,
            };
            this.OID = e.OID;
            this.selectOrganization();
        }
    };
    default_1.prototype.getOrganizations = function () {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.GetOrganizations()];
                    case 1:
                        res = _a.sent();
                        if (res.data.State === 1) {
                            this.list = res.data.Data;
                        }
                        return [2];
                }
            });
        });
    };
    default_1.prototype.selectOrganization = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, info;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.SelectOrganization({
                            OID: this.OID,
                            IsRemember: true,
                        })];
                    case 1:
                        data = (_a.sent()).data;
                        if (data.State === 1) {
                            info = {
                                UID: data.Data.ID || data.Data.UID,
                                OID: data.Data.CompanyID || data.Data.OID,
                                UserName: data.Data.UserName,
                                RememberMe: data.Data.RememberMe,
                                UserRole: data.Data.UserRole,
                                SaasID: data.Data.SaasID,
                                CompanyID: data.Data.CompanyID,
                                OrganizationShorterName: data.Data.CompanyName,
                            };
                            this.$Cache().removeInfo();
                            this.$Cache().setInfo(info);
                            this.$Cache().removeMenus();
                            window.location.href = "/";
                        }
                        return [2];
                }
            });
        });
    };
    default_1.prototype.getUserInfo = function () {
        var _this = this;
        this.getCurUserDetail().then(function (res) {
            var oResult = res.data;
            if (oResult.State === 1) {
                _this.organizationName = oResult.Data.OrganizationShorterName;
            }
        });
    };
    default_1.prototype.getHeadImg = function () {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.GetBasicUserInfo()];
                    case 1:
                        res = _a.sent();
                        if (res.data.State === 1) {
                            this.$store.commit("SETDISPOSE", {
                                HeadImgUrl: res.data.Data.HeadImgUrl,
                            });
                        }
                        return [2];
                }
            });
        });
    };
    default_1.prototype.HeadImgError = function () {
        this.$store.commit("SETDISPOSE", { HeadImgUrl: "" });
    };
    default_1.prototype.handleCommand = function (command) {
        var _this = this;
        if (command === "logout") {
            this.logout().then(function (response) {
                if (response && response.State < 0) {
                    _this.$message({
                        message: response.Message,
                        type: "error",
                    });
                }
            });
        }
    };
    __decorate([
        Action
    ], default_1.prototype, "logout", void 0);
    __decorate([
        Action("home/mycenter/GetBasicUserInfo")
    ], default_1.prototype, "GetBasicUserInfo", void 0);
    __decorate([
        Getter("config")
    ], default_1.prototype, "config", void 0);
    __decorate([
        Action("getOrganizations")
    ], default_1.prototype, "GetOrganizations", void 0);
    __decorate([
        Action("selectOrganization")
    ], default_1.prototype, "SelectOrganization", void 0);
    __decorate([
        Action("home/portal/getCurUserDetail")
    ], default_1.prototype, "getCurUserDetail", void 0);
    default_1 = __decorate([
        Component({
            components: {
                Logo: Logo,
                Navbar: Navbar,
                Screenfull: Screenfull,
                Theme: Theme,
            },
        })
    ], default_1);
    return default_1;
}(Vue));
export default default_1;
