var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Getter, Mutation, State, Watch, } from "nuxt-property-decorator";
import Crumb from "~/utils/crumb";
var default_1 = (function (_super) {
    __extends(default_1, _super);
    function default_1() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    default_1.prototype.loadMenu = function (nMenus, oMenus) {
        var _this = this;
        if (nMenus.length > 0) {
            var pathSplit = this.$route.path.match(/\/(\S*?)\//);
            var store_1 = this.$store;
            if (pathSplit) {
                var navCode_1 = pathSplit[1];
                nMenus.forEach(function (nav, index) {
                    if (nav.Code === navCode_1) {
                        _this.setActiveModule(index.toString());
                        new Crumb(nav.Childs, _this.$route.path, store_1._mutations);
                    }
                });
            }
            else if (this.$route.path === "/") {
                new Crumb(nMenus[0].Childs, this.$route.path, store_1._mutations);
            }
        }
    };
    default_1.prototype.switchModule = function (tab) {
        this.setActiveModule(tab.index);
        if (this.menus[tab.index].Childs &&
            this.menus[tab.index].Childs.length > 0) {
            this.$router.push(this.menus[tab.index].Childs[0].Url);
            this.setActiveMenu(this.menus[tab.index].Childs[0].ID);
        }
    };
    __decorate([
        Getter
    ], default_1.prototype, "menus", void 0);
    __decorate([
        State
    ], default_1.prototype, "moduleIndex", void 0);
    __decorate([
        Mutation("ACTIVEMODULE")
    ], default_1.prototype, "setActiveModule", void 0);
    __decorate([
        Mutation("ACTIVEMENU")
    ], default_1.prototype, "setActiveMenu", void 0);
    __decorate([
        Watch("menus")
    ], default_1.prototype, "loadMenu", null);
    default_1 = __decorate([
        Component
    ], default_1);
    return default_1;
}(Vue));
export default default_1;
