var state = function () { return ({}); };
var mutations = {};
var actions = {
    getBrandList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Brand/list",
            method: "GET"
        });
    },
    getSeriesList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/VehicleLine/list",
            data: Data
        });
    },
    getModelList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/VehicleModel/list",
            data: Data
        });
    },
    addvehicleModel: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/VehCommonlyUsed/add",
            data: Data
        });
    },
    removevehicleModel: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/VehCommonlyUsed/remove",
            data: Data
        });
    },
    getCurrentModel: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/VehCommonlyUsed/allmodels",
            method: "GET"
        });
    }
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
