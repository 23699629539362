var mutations = {
    SYSMENUS: function (state, data) {
        if (data) {
            data.sort(function (a, b) {
                return a.Sort - b.Sort;
            });
            state.menus = data;
            this.$Cache().setMenus(data);
        }
        else {
            state.menus = [];
            this.$Cache().setMenus([]);
        }
    },
    SETMENUS: function (state, data) {
        state.sideMenu = data;
    },
    LOGOUT: function (state) {
        this.$Cache().removeToken();
        this.$Cache().removeInfo();
        this.$Cache().removeMenus();
        this.$Cache().removeDispose();
        location.href = this.$envConfig().urlConfig.login;
    },
    ACTIVEMODULE: function (state, data) {
        state.moduleIndex = data;
        var sideMenu = state.menus[parseInt(data)].Childs || [];
        state.sideMenu = sideMenu;
    },
    ACTIVEMENU: function (state, data) {
        state.activeMenu = data;
    },
    SETBREADCRUMB: function (state, data) {
        state.breadcrumb = data;
    },
    SETCONFIG: function (state, data) {
        state.config = Object.assign({}, state.config, data);
    },
    UPDATEINFO: function (state, data) {
        var info = this.$Cache().getInfo();
        this.$Cache().setInfo(Object.assign({}, info, data));
    },
    SETDISPOSE: function (state, data) {
        if (data.HeadImgUrl) {
            state.config = Object.assign({}, state.config, data);
        }
        this.$Cache().setDispose(data);
    }
};
export default mutations;
