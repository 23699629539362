export default (function (_a, inject) {
    var app = _a.app;
    inject("vCheckform", function (params) {
        return {
            isMobile: /^((1[0-9]))\d{9}$/gi,
            isPhone: /^(((\d{3,4}-)?\d{7,8})|(1\d{10}))$/g,
            isMobileEncrypt: /^[0-9]{3}([*]{4}|[0-9]{4})[0-9]{4}$/gi,
            isEmails: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/gi,
            isIdcards: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/gi,
            isZipCode: /^[1-9][0-9]{5}$/gi,
            isNumber: /^[0-9]*$/gi,
            isFloter: /^\d+(\.\d{1,2})?$/gi,
            posInteger: /^\d+$/gi,
            isDeduct: /^(1[0-2]|[0-9])$/gi,
            posNumber: /^[1-9]+\d*(\.{0,1}\d{0,2})?$|^0?\.{0,1}\d{0,2}$/gi,
            negInteger: /^\d+$/gi,
            allInteger: /^-?\d+$/gi,
            aPositive: /^\d*\.?\d+$/gi,
            negativeNum: /^-\d*\.?\d+$/gi,
            allNumber: /^-?\d*\.?\d+$/gi,
            isUrl: /^http:\/\/[A-Za-z0-9]+\.[A-Za-z0-9]+[\/=\?%\-&_~`@[\]\':+!]*([^<>\"\"])*$/gi,
            isChinese: /^[\u4e00-\u9fa5]+$/gi,
            realName: /^(([a-zA-Z+\s?\s?a-zA-Z+]{2,20}$)|([a-zA-Z+\.?\.?a-zA-Z+]{2,20}$)|([\u4e00-\u9fa5+\.?\·?\u4e00-\u9fa5+]{2,20}$))/gi,
            userName: /^[a-zA-Z0-9_-]{4,16}$/gi,
            password: /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/gi,
            passwordTwo: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/gi,
            englishNum: /^[0-9a-zA-Z]{0,32}$/gi,
            plate: /^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1})$/gi
        };
    });
});
