var state = function () { return ({
    listParams: {
        OID: null,
        AccidentStatus: '-1',
        Money: -1,
        MinSettlementFee: null,
        MaxSettlementFee: null,
        ResponsibilityType: null,
        AccidentType: null,
        CloseStatus: null,
        TagID: null,
        KeyValue: ''
    },
    StatusType: [
        {
            Display: '待定损',
            Value: 1
        },
        {
            Display: '已定损待结案',
            Value: 2
        },
        {
            Display: '已结案',
            Value: 3
        },
        {
            Display: '已结案待理赔',
            Value: 4
        },
        {
            Display: '理赔完',
            Value: 5
        },
        {
            Display: '销案',
            Value: 6
        },
    ],
    responsibilityType: [
        {
            Display: '全部责任',
            Value: 1
        },
        {
            Display: '主要责任',
            Value: 2
        },
        {
            Display: '同等责任',
            Value: 3
        },
        {
            Display: '次要责任',
            Value: 4
        },
        {
            Display: '无责任',
            Value: 5
        }
    ],
    accidentPersonType: [
        {
            Display: '客户',
            Value: 1
        },
        {
            Display: '其他',
            Value: 2
        },
    ],
    accidentMoney: [
        {
            name: '全部',
            min: null,
            max: null,
            value: -1
        },
        {
            name: '2000-5000',
            min: 2000,
            max: 5000,
            value: 2
        },
        {
            name: '5000-10000',
            min: 5000,
            max: 10000,
            value: 3
        },
        {
            name: '10000-30000',
            min: 10000,
            max: 30000,
            value: 1
        },
        {
            name: '30000以上',
            min: 30000,
            max: null,
            value: 4
        },
    ],
    accidentType: [
        {
            Display: '单方',
            Value: 1
        },
        {
            Display: '双方',
            Value: 2
        },
        {
            Display: '多方',
            Value: 3
        },
    ],
    insuranceTags: [
        {
            Display: '人伤',
            Value: 1
        },
        {
            Display: '三者',
            Value: 2
        },
        {
            Display: '物损',
            Value: 3
        },
    ],
}); };
var getters = {
    getterParams: function (state) {
        Object.assign(state.listParams, { OID: null });
        return state.listParams;
    }
};
var mutations = {
    UPDATEPARAMS: function (state, data) {
        Object.assign(state.listParams, data);
    }
};
var actions = {
    getList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Accident/AccidentList",
            data: Data
        });
    },
    add: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Accident/AccidentCreate",
            data: Data
        });
    },
    detail: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Accident/AccidentDetail",
            data: Data
        });
    },
    update: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Accident/AccidentUpdate",
            data: Data
        });
    },
    delete: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Accident/AccidentDelete",
            data: Data
        });
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
