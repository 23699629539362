var state = function () { return ({}); };
var mutations = {};
var actions = {
    getContractTemplateList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/ContractTemplate/page",
            data: Data
        });
    },
    getTemplateKeywords: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/ContractTemplate/templateKeywords",
            data: Data
        });
    },
    ContractTemplateCreate: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/ContractTemplate/create",
            data: Data
        });
    },
    ContractTemplateUpdate: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/ContractTemplate/update",
            data: Data
        });
    },
    getContractTemplateDetail: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/ContractTemplate/get",
            data: Data
        });
    },
    FetchContractTemplateDetail: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/ContractTemplate/fetch",
            data: Data
        });
    },
    ContractTemplateDelete: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/ContractTemplate/delete",
            data: Data
        });
    },
    listByBusinessType: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/ContractTemplate/listByBusinessType",
            data: Data
        });
    },
    contractTemplateKeywordValues: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/ContractTemplate/contractTemplateKeywordValues",
            data: Data
        });
    },
    contarctTemplateConfigured: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/ContractTemplate/contarctTemplateConfigured",
            data: Data
        });
    },
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
