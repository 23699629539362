import portal from '~/store/modules/home/portal';
import approval from '~/store/modules/home/approval';
import task from '~/store/modules/home/task';
import mycenter from '~/store/modules/home/my-center';
import notice from '~/store/modules/home/notice';
var getters = {};
var mutations = {};
var actions = {};
export default {
    namespaced: true,
    state: {},
    getters: getters,
    actions: actions,
    mutations: mutations,
    modules: {
        portal: portal,
        approval: approval,
        task: task,
        mycenter: mycenter,
        notice: notice
    }
};
