var getters = {
    menus: function (state) {
        return state.menus;
    },
    currentPermission: function (state) {
        state.permission = [];
        if (state.menus[state.moduleIndex] != null) {
            var stack = [];
            stack.push(state.menus[state.moduleIndex]);
            var _loop_1 = function () {
                var item = stack.pop();
                if (item.ID === state.activeMenu) {
                    if (item.Childs && item.Childs.length > 0) {
                        var pathName_1 = location.pathname;
                        item.Childs.forEach(function (tab) {
                            if (pathName_1.indexOf(tab.Url) >= 0) {
                                state.permission = state.permission.concat(tab.Functions ? tab.Functions : []);
                            }
                        });
                        state.permission = state.permission.concat(item.Functions ? item.Functions : []);
                    }
                    else {
                        state.permission = item.Functions ? item.Functions : [];
                    }
                    console.table(JSON.parse(JSON.stringify(state.permission)));
                    return "break";
                }
                var childs = item.Childs;
                if (childs != null) {
                    for (var i = childs.length - 1; i >= 0; i--) {
                        stack.push(childs[i]);
                    }
                }
            };
            while (stack.length != 0) {
                var state_1 = _loop_1();
                if (state_1 === "break")
                    break;
            }
        }
        return state.permission;
    },
    config: function (state) {
        return state.config;
    }
};
export default getters;
