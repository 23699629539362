var state = function () { return ({}); };
var mutations = {};
var actions = {
    getVehicleModel: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Goods/GetVehicleModel',
            data: aData
        });
    },
    GetVehicleModels: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/StockInOutCheckConfig/GetVehicleModels',
            data: aData
        });
    }
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
