var state = {};
var getters = {};
var mutations = {};
var actions = {
    shortcutMenu: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "user/SysUser/ShortcutMenu",
            data: data
        });
    },
    setShortcutMenu: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "user/SysUser/SetShortcutMenu",
            data: data
        });
    },
    getNewsList: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "mobile/News/GetList",
            data: data
        });
    },
    GetListByApp: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "mobile/News/GetListByApp",
            data: data
        });
    },
    getNewsCategories: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "mobile/News/Categories",
            data: data
        });
    },
    getNewsDetailsByID: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "mobile/News/GetNewsDetailsByID",
            data: data
        });
    },
    WbDriverStatistics: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "user/UserDriver/WbDriverStatistics",
            data: data
        });
    },
    WbVehicleStatistics: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Vehicle/WbVehicleStatistics",
            data: data
        });
    },
    WbContractStatistics: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Contract/WbContractStatistics",
            data: data
        });
    },
    getOperationLog: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/OperationLog/page",
            data: data
        });
    },
    getCurUserDetail: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "user/SysUser/CurUserDetail",
            data: data
        });
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
