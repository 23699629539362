var state = function () { return ({
    listParams: {
        OID: null,
        KeyValue: "",
        VehicleDisposalWay: null,
        Page: 1,
        PerPage: 14
    },
    disposeList: [
        {
            name: "二手处置",
            value: 1
        },
        {
            name: "个人买断",
            value: 2
        },
        {
            name: "提前报废",
            value: 3
        },
        {
            name: "公司回购",
            value: 4
        },
        {
            name: "车辆过户",
            value: 5
        },
        {
            name: "退回车源方",
            value: 7
        }
    ]
}); };
var getters = {
    getterParams: function (state) {
        Object.assign(state.listParams, { OID: null });
        return state.listParams;
    }
};
var mutations = {
    UPDATEPARAMS: function (state, data) {
        Object.assign(state.listParams, data);
    }
};
var actions = {
    getList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "Traffic/VehicleDisposal/page",
            data: Data
        });
    },
    detail: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            method: "GET",
            apiName: "Traffic/VehicleDisposal/" + Data
        });
    },
    add: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "Traffic/VehicleDisposal/create",
            data: Data
        });
    },
    update: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "Traffic/VehicleDisposal/update",
            data: Data
        });
    },
    delete: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "Traffic/VehicleDisposal/delete",
            data: Data
        });
    }
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
