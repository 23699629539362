var state = function () { return ({}); };
var mutations = {};
var actions = {
    addSubCompany: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Organization/Create',
            data: aData
        });
    },
    getOrganizationListV2: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Organization/GetOrganizationListV2',
            data: aData
        });
    },
    inviteManagerAgain: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Organization/InviteManagerAgain',
            data: aData
        });
    },
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
