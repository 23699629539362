var state = {
    stateParams: {}
};
var getters = {};
var mutations = {};
var actions = {
    getProductList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/product/getlist",
            data: Data
        });
    },
    getProductDetails: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/product/details",
            data: Data
        });
    },
    getThirdApiList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/thirdApi/getlist",
        });
    },
    getlistForProduct: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/thirdApi/getlistForProduct",
        });
    },
    saveProduct: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/product/save",
            data: Data
        });
    },
    delProduct: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/product/del",
            data: Data
        });
    },
    updateThirdApi: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/thirdApi/update",
            data: Data
        });
    },
    orderListForSaas: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/orderForPc/orderListForSaas",
            data: Data
        });
    },
    orderReportListForSaas: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/orderForPc/orderReportListForSaas",
            data: Data
        });
    },
    getUserWxList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "user/UserWx/GetList",
            data: Data
        });
    },
    orderDetailForSaas: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/orderForPc/orderDetailForSaas",
            data: Data
        });
    },
    getProductSimpleList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/product/getProductSimpleList",
            data: Data
        });
    },
    queryRecords: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/thirdApi/queryRecords",
            data: Data
        });
    },
    getConfigList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/weixin/getConfigList",
            data: Data
        });
    },
    saveConfig: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/weixin/saveConfig",
            data: Data
        });
    },
    getListHelp: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/weixin/getList",
            data: Data
        });
    },
    saveHelp: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/weixin/save",
            data: Data
        });
    },
    delHelp: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/weixin/del",
            data: Data
        });
    },
    reportDetailForSaas: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/orderForPc/reportDetailForSaas",
            data: Data
        });
    },
    getOrderPayRecords: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/orderForPc/getOrderPayRecords",
            data: Data
        });
    },
    getProductListForQuery: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/orderForPc/getProductListForQuery",
        });
    },
    pcQuery: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/orderForPc/pcQuery",
            data: Data
        });
    },
    reportStatus: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/orderForPc/reportStatus",
            data: Data
        });
    },
    queryWeiXinOrder: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/orderForPc/queryWeiXinOrder",
            data: Data
        });
    },
    getReportPdfForSaas: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/orderForPc/getReportPdfForSaas",
            data: Data
        });
    },
    getReportPdfForTest: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "credit/orderForPc/getReportPdfForTest",
            data: Data
        });
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
