var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Action } from "nuxt-property-decorator";
import Header from '~/components/swt/layout/header/Header.vue';
import Sidebar from '~/components/swt/layout/sidebar/Sidebar.vue';
import Main from '~/components/swt/layout/main/Main.vue';
var default_1 = (function (_super) {
    __extends(default_1, _super);
    function default_1() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = false;
        return _this;
    }
    default_1.prototype.mounted = function () {
        var _this = this;
        this.loading = true;
        this.loadMenus().then(function (res) {
            _this.loading = false;
            if (res.data.State === 1) {
                _this.$store.commit('SYSMENUS', _this.convertMenus(res.data.Data));
                if (_this.$route.path === '/' || _this.$route.path === '/home') {
                    if (res.data.Data.length && res.data.Data[0].Url) {
                        _this.$router.push({ path: res.data.Data[0].Url, redirect: true });
                    }
                    else {
                        _this.$router.push({ path: res.data.Data[0].Childs[0].Url, redirect: true });
                    }
                }
            }
        });
    };
    default_1.prototype.convertMenus = function (menus) {
        var _this = this;
        var toMenus = [];
        if (menus === null) {
            menus = [];
        }
        menus.forEach(function (menu) {
            var childMenu = menu.Childs ? menu.Childs : [];
            var isSingle = childMenu.some(function (menu) {
                if (!menu.IsShowNav) {
                    return true;
                }
                return false;
            });
            childMenu.sort(function (a, b) {
                return a.Sort - b.Sort;
            });
            toMenus.push({
                ID: menu.ID,
                Name: menu.Name,
                Parent: menu.Parent,
                Icon: menu.Icon,
                Code: menu.Code,
                FunctionType: menu.FunctionType,
                Functions: menu.Functions,
                Level: menu.Level,
                Sort: menu.Sort,
                IsShowNav: menu.IsShowNav,
                Url: menu.Url,
                Childs: _this.convertMenus(childMenu),
                isSingle: isSingle
            });
        });
        return toMenus;
    };
    __decorate([
        Action
    ], default_1.prototype, "loadMenus", void 0);
    default_1 = __decorate([
        Component({
            components: {
                Header: Header,
                Sidebar: Sidebar,
                Main: Main
            }
        })
    ], default_1);
    return default_1;
}(Vue));
export default default_1;
