var state = function () { return ({
    dataMerchandiseDeatail: {
        "MID": 0,
        "GoodsDescription": "",
        "ImageDescription": "",
        "GoodsType": 0,
        "Maintain": "",
        "Repair": "",
        "OID": 0,
        "PickCarRound": 0,
        "IsSale": true,
        "IsTop": true,
        "GoodsImageList": [],
        "TagList": [],
        "InsuranceList": [],
        "SchemeList": [],
        "ID": 0,
        "Timestamps": 0,
        "CreateTime": "",
        "CreateBy": 0,
        "UpdateTime": "",
        "UpdateBy": 0,
        "IsDel": true
    },
    listParams: {
        OID: null,
        MID: -1,
        BusinessType: -1,
        SaleStatus: -1,
        SaleScope: -1,
        KeyValue: ''
    },
}); };
var getters = {
    getterParams: function (state) {
        Object.assign(state.listParams, { OID: null });
        return state.listParams;
    }
};
var mutations = {
    setMerchandiseDeatail: function (state, aData) {
        aData.SchemeList = aData.SchemeList.map(function (item) {
            item.SaleStatus = +item.IsSale;
            return item;
        });
        state.dataMerchandiseDeatail = aData;
    },
    setInitDataMerchandiseDeatail: function (state, aData) {
        state.dataMerchandiseDeatail = {
            "MID": 0,
            "GoodsDescription": "",
            "ImageDescription": "",
            "GoodsType": 0,
            "Maintain": "",
            "Repair": "",
            "OID": 0,
            "PickCarRound": 0,
            "IsSale": true,
            "IsTop": true,
            "GoodsImageList": [],
            "TagList": [],
            "InsuranceList": [],
            "SchemeList": [],
            "ID": 0,
            "Timestamps": 0,
            "CreateTime": "",
            "CreateBy": 0,
            "UpdateTime": "",
            "UpdateBy": 0,
            "IsDel": true
        };
    },
    UPDATEPARAMS: function (state, data) {
        Object.assign(state.listParams, data);
    }
};
var actions = {
    getMerchandiseList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Goods/GetList',
            data: aData
        });
    },
    getGoodsSaleCount: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Goods/GetSaleCount',
            data: aData
        });
    },
    getGoodsListByOid: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Goods/GetGoodsListByOid',
            data: aData
        });
    },
    delMerchandise: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Goods/Del',
            data: aData
        });
    },
    onOrOffShelf: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Goods/SaleScheme',
            data: aData
        });
    },
    toOrBackTopWay: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Goods/TopScheme',
            data: aData
        });
    },
    getMerchandiseDeatail: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        this.$apiAjax({
            apiName: 'saas/Goods/GetDetail',
            data: aData
        }).then(function (res) {
            var oResult = res.data;
            if (oResult.State === 1) {
                commit('setMerchandiseDeatail', oResult.Data);
            }
        });
    },
    getMerchTagsList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Tags/GetList',
            data: aData
        });
    },
    addMerchandise: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Goods/Create',
            data: aData
        });
    },
    editMerchandise: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Goods/Update',
            data: aData
        });
    },
    getInsurancesLists: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Insurances/GetList'
        });
    },
    addInsurancesTag: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Insurances/Create',
            data: aData
        });
    },
    delInsurancesTag: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Insurances/Del',
            data: aData
        });
    },
    getGoodsShopCode: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Goods/GetShopCode',
            data: aData
        });
    },
    getSchemeFeeByBusinessType: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/SchemeFeeConfig/GetSchemeFeeByBusinessType',
            data: aData
        });
    },
    getFeePayType: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/SchemeFeeConfig/GetFeePayType'
        });
    }
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
