import Cookies from 'js-cookie';
var TokenKey = 'saas-token';
var UserInfo = 'saas-info';
var UserDiy = 'Vwiov-Diy';
var SysMenus = 'saas-menus';
var LocalDispose = 'saas-Dispose';
export default (function (_a, inject) {
    var env = _a.env;
    inject('Cache', function () {
        return {
            getToken: function () {
                return Cookies.get(TokenKey);
            },
            setToken: function (token) {
                return Cookies.set(TokenKey, token, env.cookieConfig);
            },
            removeToken: function () {
                return Cookies.remove(TokenKey, env.cookieConfig);
            },
            getInfo: function () {
                return Cookies.getJSON(UserInfo) || {};
            },
            setInfo: function (info) {
                return Cookies.set(UserInfo, info, env.cookieConfig);
            },
            removeInfo: function () {
                return Cookies.remove(UserInfo, env.cookieConfig);
            },
            getDiy: function () {
                return Cookies.getJSON(UserDiy) || {};
            },
            setDiy: function (diy) {
                return Cookies.set(UserDiy, diy);
            },
            removeDiy: function () {
                return Cookies.remove(UserDiy);
            },
            getMenus: function () {
                var menus = window.localStorage.getItem(SysMenus);
                return JSON.parse(menus);
            },
            setMenus: function (menus) {
                return window.localStorage.setItem(SysMenus, JSON.stringify(menus));
            },
            removeMenus: function () {
                return window.localStorage.removeItem(SysMenus);
            },
            getDispose: function () {
                var dispose = window.localStorage.getItem(LocalDispose);
                return JSON.parse(dispose);
            },
            setDispose: function (dispose) {
                return window.localStorage.setItem(LocalDispose, JSON.stringify(dispose));
            },
            removeDispose: function () {
                return window.localStorage.removeItem(LocalDispose);
            }
        };
    });
});
