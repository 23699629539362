import supplier from '~/store/modules/merchant/supplier';
import service from '~/store/modules/merchant/service';
var state = function () { return ({}); };
var mutations = {};
var actions = {};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {
        supplier: supplier,
        service: service
    }
};
