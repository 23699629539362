import customer from '~/store/modules/rent/customer';
import ordermanage from '~/store/modules/rent/ordermanage';
import contract from '~/store/modules/rent/contract';
import econtract from '~/store/modules/rent/econtract';
import coupon from '~/store/modules/rent/coupon';
import accountancy from '~/store/modules/rent/accountancy';
import carteam from '~/store/modules/rent/carteam';
import couponStatistics from '~/store/modules/rent/coupon-statistics';
import dereaseManage from '~/store/modules/rent/decrease-manage';
var state = function () { return ({}); };
var mutations = {};
var actions = {};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {
        customer: customer,
        ordermanage: ordermanage,
        contract: contract,
        coupon: coupon,
        accountancy: accountancy,
        carteam: carteam,
        couponStatistics: couponStatistics,
        dereaseManage: dereaseManage,
        econtract: econtract
    }
};
