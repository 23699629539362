var initState = {
    stateParams: {}
};
var getters = {};
var mutations = {
    UPDATEPARAMS: function (state, data) {
        Object.assign(state.stateParams, data);
    }
};
var actions = {
    getFlow: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/flowUse/getFlowItemsWithStatistics",
            data: data
        });
    },
    getPending: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/flowUse/getList",
            data: data
        });
    },
    getMonitor: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/flowUse/getMonitorList",
            data: data
        });
    },
    check: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/flowUse/check",
            data: data
        });
    },
    forceStop: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/flowUse/forceStop",
            data: data
        });
    },
    detail: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/flowUse/Details",
            data: data
        });
    }
};
export default {
    namespaced: true,
    state: initState,
    getters: getters,
    actions: actions,
    mutations: mutations
};
