var state = function () { return ({}); };
var mutations = {};
var actions = {
    getList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/SchemeFeeConfig/GetList",
            data: Data
        });
    },
    getPayTypeList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/SchemeFeeConfig/GetFeePayTypeByConfig",
            data: Data
        });
    },
    addFee: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/SchemeFeeConfig/Create",
            data: Data
        });
    },
    updateFee: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/SchemeFeeConfig/Update",
            data: Data
        });
    },
    deleteFee: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/SchemeFeeConfig/Delete",
            data: Data
        });
    },
    getListByBusinessType: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/SchemeFeeConfig/GetSchemeFeeByBusinessType",
            data: Data
        });
    },
    updateFeeConfig: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/SchemeFeeConfig/UpdateSchemeFeeBusinessType",
            data: Data
        });
    },
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
