import { render, staticRenderFns } from "./fit.vue?vue&type=template&id=034bea64&"
var script = {}
import style0 from "./fit.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "fit.vue"
export default component.exports