var state = function () { return ({}); };
var mutations = {};
var actions = {
    getListBySend: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/coupon/getListBySend'
        });
    },
    getCouponList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/coupon/list',
            data: aData
        });
    },
    getCouponGetById: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/coupon/getById',
            data: aData
        });
    },
    couponCreate: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/coupon/create',
            data: aData
        });
    },
    couponDel: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/coupon/del',
            data: aData
        });
    },
    couponUpdate: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/coupon/update',
            data: aData
        });
    },
    couponChangeStatus: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/coupon/changeStatus',
            data: aData
        });
    },
    getCouponSendStatus: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/coupon/getCouponSendStatus',
            data: aData
        });
    },
    getCouponSendList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/coupon/getCouponSendList',
            data: aData
        });
    },
    cancelCouponSend: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/coupon/cancelCouponSend',
            data: aData
        });
    },
    updateCouponSend: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/coupon/updateCouponSend',
            data: aData
        });
    },
    getCouponSendDetail: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/coupon/getCouponSendDetail',
            data: aData
        });
    },
    exportExcelCouponSend: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/coupon/exportExcelCouponSend',
            data: aData
        });
    },
    getContractListBySingSend: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/coupon/getContractListBySingSend',
            data: aData
        });
    },
    getContractList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/coupon/getContractList',
            data: aData
        });
    },
    sendCoupon: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/coupon/sendCoupon',
            data: aData
        });
    }
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
