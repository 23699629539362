var state = function () { return ({}); };
var mutations = {};
var actions = {
    GetBasicUserInfo: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/UserCenter/GetBasicUserInfo'
        });
    },
    UpdateBasicUserInfo: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            data: Data,
            apiName: 'user/UserCenter/UpdateBasicUserInfo'
        });
    },
    GetBasicCompanyInfo: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/UserCenter/GetBasicCompanyInfo'
        });
    },
    QuitCompany: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            data: Data,
            apiName: 'user/UserCenter/QuitCompany'
        });
    },
    ChangeMobile: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            data: Data,
            apiName: 'user/UserCenter/ChangeMobile'
        });
    },
    UpdateUserPassword: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            data: Data,
            apiName: 'user/UserCenter/UpdateUserPassword'
        });
    },
    UpdatePasswordByMobile: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            data: Data,
            apiName: 'user/UserCenter/UpdatePasswordByMobile'
        });
    },
    GetCode: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            data: Data,
            apiName: 'user/User/GetCode'
        });
    },
    CheckCodeInfo: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            data: Data,
            apiName: 'user/User/CheckCodeInfo'
        });
    },
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
