var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Watch } from 'nuxt-property-decorator';
var ORIGINAL_THEME = '#59597d';
var default_1 = (function (_super) {
    __extends(default_1, _super);
    function default_1() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.theme = ORIGINAL_THEME;
        return _this;
    }
    default_1.prototype.change = function (val, oldVal) {
        var _this = this;
        var self = this;
        if (typeof val !== "string")
            return;
        var themeCluster = this.getThemeCluster(val.replace("#", ""));
        var originalCluster = this.getThemeCluster(oldVal.replace("#", ""));
        var getHandler = function (variable, id) {
            return function () {
                var originalCluster = _this.getThemeCluster(ORIGINAL_THEME.replace("#", ""));
                var newStyle = _this.updateStyle(_this[variable], originalCluster, themeCluster);
                var styleTag = document.getElementById(id);
                if (!styleTag) {
                    styleTag = document.createElement("style");
                    styleTag.setAttribute("id", id);
                    document.head.appendChild(styleTag);
                }
                styleTag.innerText = newStyle;
            };
        };
        var chalkHandler = getHandler("chalk", "chalk-style");
        if (!self.chalk) {
            var url = 'https://unpkg.com/element-ui@2.4.11/lib/theme-chalk/index.css';
            this.getCSSString(url, chalkHandler, "chalk");
        }
        else {
            chalkHandler();
        }
    };
    default_1.prototype.updateStyle = function (style, oldCluster, newCluster) {
        var newStyle = style;
        oldCluster.forEach(function (color, index) {
            newStyle = newStyle.replace(new RegExp(color, "ig"), newCluster[index]);
        });
        return newStyle;
    };
    default_1.prototype.getCSSString = function (url, callback, variable) {
        var _this = this;
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                _this[variable] = xhr.responseText.replace(/@font-face{[^}]+}/, '');
                callback();
            }
        };
        xhr.open('GET', url);
        xhr.send();
    };
    default_1.prototype.getThemeCluster = function (theme) {
        var tintColor = function (color, tint) {
            var red = parseInt(color.slice(0, 2), 16);
            var green = parseInt(color.slice(2, 4), 16);
            var blue = parseInt(color.slice(4, 6), 16);
            if (tint === 0) {
                return [red, green, blue].join(",");
            }
            else {
                red += Math.round(tint * (255 - red));
                green += Math.round(tint * (255 - green));
                blue += Math.round(tint * (255 - blue));
                return "#" + red.toString(16) + green.toString(16) + blue.toString(16);
            }
        };
        var shadeColor = function (color, shade) {
            var red = parseInt(color.slice(0, 2), 16);
            var green = parseInt(color.slice(2, 4), 16);
            var blue = parseInt(color.slice(4, 6), 16);
            red = Math.round((1 - shade) * red);
            green = Math.round((1 - shade) * green);
            blue = Math.round((1 - shade) * blue);
            return "#" + red.toString(16) + green.toString(16) + blue.toString(16);
        };
        var clusters = [theme];
        for (var i = 0; i <= 9; i++) {
            clusters.push(tintColor(theme, Number((i / 10).toFixed(2))));
        }
        clusters.push(shadeColor(theme, 0.1));
        return clusters;
    };
    __decorate([
        Watch('theme')
    ], default_1.prototype, "change", null);
    default_1 = __decorate([
        Component
    ], default_1);
    return default_1;
}(Vue));
export default default_1;
