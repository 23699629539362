import Vue from 'vue';
export default (function (_a) {
    var store = _a.store;
    var setDisabled = function (el, disabled) {
        if (disabled) {
            if (el.parentNode) {
                el.parentNode.removeChild(el);
            }
        }
    };
    var getCurrentPermission = function (Model) {
        var menu = window.localStorage.getItem('saas-menus');
        var arr = JSON.parse(menu);
        if (Model && Model.length) {
            Model.map(function (item, index) {
                if (index === Model.length - 1) {
                    arr = filterData(item, arr).Functions;
                }
                else {
                    arr = filterData(item, arr).Childs;
                }
            });
        }
        else {
            arr = [];
        }
        return arr;
    };
    var filterData = function (model, arr) {
        return arr.find(function (item) {
            return item.FunctionType === model;
        });
    };
    var isHas = function (param) {
        var Arr = getCurrentPermission(param.Model) || store.getters.currentPermission;
        return Arr.some(function (item, index, array) {
            return item.Code === param.Code;
        });
    };
    Vue.directive('has', {
        inserted: function (el, binding, vnode) {
            var param = binding.value;
            if (param) {
                setDisabled(el, !isHas(param));
            }
            else {
                setDisabled(el, true);
            }
        }
    });
});
