var state = function () { return ({
    listParams: {
        OID: null,
        VehAnnualInspectionStatus: '-1',
        StartDate: null,
        EndDate: null,
        KeyValue: ''
    },
    recordParams: {
        OID: null,
        BillStatus: null,
        StartDate: null,
        EndDate: null,
        KeyValue: ''
    },
    StatusType: [
        {
            name: '全部',
            value: -1
        },
        {
            name: '将到期',
            value: 0
        },
        {
            name: '过期',
            value: 1
        },
        {
            name: '正常',
            value: 2
        },
    ],
    annualResult: [
        {
            name: '合格',
            value: 0
        },
        {
            name: '不合格',
            value: 1
        }
    ],
    transactorType: [
        {
            name: '公司',
            value: 0
        },
        {
            name: '客户',
            value: 1
        }
    ]
}); };
var getters = {
    getterParams: function (state) {
        Object.assign(state.listParams, { OID: null });
        return state.listParams;
    },
    getterRecordParams: function (state) {
        Object.assign(state.recordParams, { OID: null });
        return state.recordParams;
    },
};
var mutations = {
    UPDATEPARAMS: function (state, data) {
        Object.assign(state.listParams, data);
    },
    UPDATERECORDPARAMS: function (state, data) {
        Object.assign(state.recordParams, data);
    },
};
var actions = {
    getList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/VehAnnualInspection/GetLastVehAnnualInspectionRecords",
            data: Data
        });
    },
    record: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/VehAnnualInspection/GetVehAnnualInspectionRecords",
            data: Data
        });
    },
    add: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/VehAnnualInspection/AddVehAnnualInspection",
            data: Data
        });
    },
    detail: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/VehAnnualInspection/VehAnnualInspectionDetails",
            data: Data
        });
    },
    update: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/VehAnnualInspection/Update",
            data: Data
        });
    },
    delete: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/VehAnnualInspection/DeleteVehAnnualInspection",
            data: Data
        });
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
