var state = function () { return ({
    listParams: {
        OID: null,
        BusinessType: -1,
        OrderStatus: -1,
        StartCarDate: '',
        EndCarDate: '',
        OrderType: -1,
        KeyValue: ''
    },
}); };
var getters = {
    getterParams: function (state) {
        Object.assign(state.listParams, { OID: null });
        return state.listParams;
    }
};
var mutations = {
    UPDATEPARAMS: function (state, data) {
        Object.assign(state.listParams, data);
    }
};
var actions = {
    getSchemeListByGoodsId: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Scheme/GetSchemeListByGoodsId',
            data: aData
        });
    },
    getOrdersList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Orders/GetOrdersList',
            data: aData
        });
    },
    addOrderBooking: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Orders/AddOrder',
            data: aData
        });
    },
    updateOrderBooking: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Orders/UpdateOrder',
            data: aData
        });
    },
    cancelOrderBooking: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Orders/CancelOrder',
            data: aData
        });
    },
    getOrderDetailsById: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Orders/GetOrderDetailsById',
            data: aData
        });
    },
    getOrdersCountByOID: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Orders/GetOrdersCountByOID',
            data: aData
        });
    },
    CheckOrder: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Orders/CheckOrder',
            data: aData
        });
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
