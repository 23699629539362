var state = function () { return ({
    listParams: {
        OID: '',
        Status: -1,
        MinOverdueDaysTotalAmount: null,
        MaxOverdueDaysTotalAmount: null,
        MinOverdueDays: null,
        MaxOverdueDays: null,
        KeyValue: ''
    }
}); };
var getters = {
    getterParams: function (state) {
        Object.assign(state.listParams, { OID: null });
        return state.listParams;
    }
};
var mutations = {
    UPDATEPARAMS: function (state, data) {
        Object.assign(state.listParams, data);
    }
};
var actions = {
    getList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Bills/GetDriverSummaryBills",
            data: Data
        });
    },
    detail: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Bills/GetDriverDetails",
            data: Data
        });
    },
    GetDriverBills: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Bills/GetDriverBills",
            data: Data
        });
    },
    ContractList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Contract/page",
            data: Data
        });
    },
    VehicleOrderList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/VehicleOrder/List",
            data: Data
        });
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
