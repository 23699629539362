var initState = {
    menus: [],
    sideMenu: [],
    permission: {},
    moduleIndex: '0',
    activeMenu: '',
    breadcrumb: [],
    config: {
        isPadding: true,
        HeadImgUrl: ''
    }
};
export default initState;
