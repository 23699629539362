var state = function () { return ({
    financeType: [
        {
            name: '客户',
            value: 1
        },
        {
            name: '供应商',
            value: 2
        }
    ]
}); };
var mutations = {
    setListParams: function (state, Data) {
        state.listParams = Data;
    }
};
var actions = {
    getList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/TrafficBill/GetBillList",
            data: Data
        });
    },
    add: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/TrafficBill/AddBill",
            data: Data
        });
    },
    update: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/TrafficBill/UpdateBill",
            data: Data
        });
    },
    delete: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/TrafficBill/DeleteBill",
            data: Data
        });
    },
    searchDriverList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/UserDriver/Search',
            data: aData
        });
    },
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
