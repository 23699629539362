var state = function () { return ({
    listParams: {
        OID: '',
        BusinessType: -1,
        ContractBillStatus: -1,
        BillBusinessType: -1,
        MinOverdueAmount: null,
        MaxOverdueAmount: null,
        MinOverdueDuration: null,
        MaxOverdueDuration: null,
        KeyValue: ''
    }
}); };
var getters = {
    getterParams: function (state) {
        Object.assign(state.listParams, { OID: null });
        return state.listParams;
    }
};
var mutations = {
    UPDATEPARAMS: function (state, data) {
        Object.assign(state.listParams, data);
    }
};
var actions = {
    getList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Bills/ContractBillList",
            data: Data
        });
    },
    detail: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Bills/GetCustomerBillDetailByBillId",
            data: Data
        });
    },
    ContractBillDetailList: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Bills/ContractBillDetailList",
            data: data
        });
    },
    ContractDetail: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Contract/" + data,
        });
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
