var state = function () { return ({}); };
var mutations = {};
var actions = {
    exportFile: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: Data.url,
            data: Data.params,
            isMsg: false,
            config: {
                responseType: "blob"
            }
        });
    }
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
