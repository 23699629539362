var state = function () { return ({
    listParams: {
        OID: null,
        BussinessType: '',
        ChangeType: '',
        Status: '',
        KeyValue: ''
    },
}); };
var getters = {
    getterParams: function (state) {
        Object.assign(state.listParams);
        return state.listParams;
    }
};
var mutations = {
    UPDATEPARAMS: function (state, data) {
        Object.assign(state.listParams, data);
    }
};
var actions = {
    getContractPage: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Contract/page',
            data: aData
        });
    },
    getContractDetail: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Contract/' + aData.id,
            method: 'get'
        });
    },
    contractCreate: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Contract/create',
            data: aData
        });
    },
    contractCancel: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Contract/cancel',
            data: aData
        });
    },
    contractUpdate: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Contract/update',
            data: aData
        });
    },
    contractChangeVehicle: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Contract/ChangeVehicle',
            data: aData
        });
    },
    contractContractRenewal: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Contract/ContractRenewal',
            data: aData
        });
    },
    contractStatus: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Contract/status',
            method: 'get'
        });
    },
    contractBusinessTypes: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Contract/BusinessTypes',
            method: 'get'
        });
    },
    searchContractListByKeyWord: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: aData.apiUrl,
            data: aData.params
        });
    },
    getContractPreviewByID: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Contract/GetContractPreviewByID?id=' + aData.id,
            method: 'get'
        });
    },
    contractCancellation: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Contract/ContractCancellation',
            data: aData
        });
    },
    getBusinessSchemeList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Scheme/list/' + aData.businessTypeID,
            method: 'get'
        });
    },
    getSchemeDetail: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Scheme/' + aData.id,
            method: 'get'
        });
    },
    contractTemporarily: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Contract/ContractTemporarily',
            data: aData
        });
    },
    contractVelicles: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Vehicle/ContractVelicles',
            data: aData
        });
    },
    getListByRentDay: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/SysConfig/GetListByRentDay'
        });
    },
    getAssociated: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Contract/associated/' + aData.id,
            method: 'get'
        });
    },
    getContractRecord: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Contract/record/' + aData.cid,
            method: 'get'
        });
    },
    allowchangevehiclecontracts: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Contract/allowchangevehiclecontracts',
            data: aData
        });
    },
    contractRepeal: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Contract/Repeal',
            data: aData
        });
    },
    getContractsForCancellation: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Contract/GetContractsForCancellation',
            data: aData
        });
    },
    changeScheme: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Contract/ChangeScheme',
            data: aData
        });
    },
    getVehicleinoutlist: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/StockInOuts/vehicleinoutlist/' + aData.cid
        });
    },
    contractTemporarilyStockOut: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Contract/ContractTemporarilyStockOut',
            data: aData
        });
    },
    getContracts: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Contract/GetContracts',
            data: aData
        });
    },
    getNextReviewer: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Flows/GetNextReviewer?itemType=' + aData.itemType
        });
    },
    getViolationlist: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/TrafficViolation/GetAllByPersonItem",
            data: aData
        });
    },
    createAttachment: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Attachment/create",
            data: aData
        });
    },
    getAttachment: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Attachment/all/" + aData.entryId
        });
    },
    deleteAttachment: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Attachment/delete",
            data: aData
        });
    },
    getChangedetail: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Contract/changedetail/" + aData.id
        });
    },
    getAllByContractID: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/TrafficViolation/GetAllByContractID?CID=" + aData.CID
        });
    },
    GetNotOperatingBySchemeId: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Vehicle/GetNotOperatingBySchemeId",
            data: aData
        });
    },
    GetVehicleOrderList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/VehicleOrder/List",
            data: aData
        });
    },
    GetVehicleOrderDetails: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/VehicleOrder/Details",
            data: aData
        });
    },
    GetOrderDetailViolations: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "violation/ViolationQuery/GetOrderDetailViolations",
            data: aData
        });
    },
    GetRepaymentPlans: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Contract/GetRepaymentPlans",
            data: aData
        });
    },
    GetDriverIDByUserId: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "user/UserDriver/GetDriverIDByUserId",
            data: aData
        });
    },
    ContractDelay: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Contract/ContractDelay",
            data: aData
        });
    },
    ContractCancellationDetail: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Contract/ContractCancellationDetail",
            data: aData
        });
    },
    ChangeSchemePreview: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Contract/ChangeSchemePreview",
            data: aData
        });
    },
    ContractRenewalPreview: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Contract/ContractRenewalPreview",
            data: aData
        });
    },
    ContractTemporarilyPreview: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Contract/ContractTemporarilyPreview",
            data: aData
        });
    },
    ContractDelayPreview: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Contract/ContractDelayPreview",
            data: aData
        });
    },
    ContractCreatePreview: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Contract/ContractCreatePreview",
            data: aData
        });
    },
    ContractCancellationPreview: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Contract/ContractCancellationPreview",
            data: aData
        });
    },
    ChangeVehiclePreview: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Contract/ChangeVehiclePreview",
            data: aData
        });
    },
    GetContractChangeRecordList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "Saas/Contract/GetContractChangeRecordList",
            data: aData
        });
    },
    GetChangeVehicleInfo: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "Saas/Contract/GetChangeVehicleInfo",
            data: aData
        });
    },
    GetChangeSchemeInfo: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "Saas/Contract/GetChangeSchemeInfo",
            data: aData
        });
    },
    GetRenewalInfo: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "Saas/Contract/GetRenewalInfo",
            data: aData
        });
    },
    GetTemporarilyInfo: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "Saas/Contract/GetTemporarilyInfo",
            data: aData
        });
    },
    GetContractCancellationInfo: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "Saas/Contract/GetContractCancellationInfo",
            data: aData
        });
    },
    GetContractDelayInfo: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "Saas/Contract/GetContractDelayInfo",
            data: aData
        });
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
