import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _2837fc57 = () => interopDefault(import('../client/pages/business.vue' /* webpackChunkName: "pages/business" */))
const _d954b4cc = () => interopDefault(import('../client/pages/business/index.vue' /* webpackChunkName: "pages/business/index" */))
const _8ffea846 = () => interopDefault(import('../client/pages/business/business-manage/index.vue' /* webpackChunkName: "pages/business/business-manage/index" */))
const _ca1d4364 = () => interopDefault(import('../client/pages/business/merchandise/index.vue' /* webpackChunkName: "pages/business/merchandise/index" */))
const _19475cd4 = () => interopDefault(import('../client/pages/business/subscribe-order/index.vue' /* webpackChunkName: "pages/business/subscribe-order/index" */))
const _79d8ef7c = () => interopDefault(import('../client/pages/business/action-manage/coupon-statistics/index.vue' /* webpackChunkName: "pages/business/action-manage/coupon-statistics/index" */))
const _08d8b4d4 = () => interopDefault(import('../client/pages/business/action-manage/sale-action/index.vue' /* webpackChunkName: "pages/business/action-manage/sale-action/index" */))
const _1de90d59 = () => interopDefault(import('../client/pages/business/business-manage/detail/index.vue' /* webpackChunkName: "pages/business/business-manage/detail/index" */))
const _031dd034 = () => interopDefault(import('../client/pages/business/action-manage/sale-action/add/index.vue' /* webpackChunkName: "pages/business/action-manage/sale-action/add/index" */))
const _255ebe30 = () => interopDefault(import('../client/pages/business/merchandise/detail/_id.vue' /* webpackChunkName: "pages/business/merchandise/detail/_id" */))
const _3270725c = () => interopDefault(import('../client/pages/business/merchandise/save/_id.vue' /* webpackChunkName: "pages/business/merchandise/save/_id" */))
const _b89ed030 = () => interopDefault(import('../client/pages/business/subscribe-order/detail/_id.vue' /* webpackChunkName: "pages/business/subscribe-order/detail/_id" */))
const _0bef16d0 = () => interopDefault(import('../client/pages/credit.vue' /* webpackChunkName: "pages/credit" */))
const _5be6fa1e = () => interopDefault(import('../client/pages/credit/account/index.vue' /* webpackChunkName: "pages/credit/account/index" */))
const _0e5bf764 = () => interopDefault(import('../client/pages/credit/agreement/index.vue' /* webpackChunkName: "pages/credit/agreement/index" */))
const _750e52f7 = () => interopDefault(import('../client/pages/credit/inquire/index.vue' /* webpackChunkName: "pages/credit/inquire/index" */))
const _f9c09ddc = () => interopDefault(import('../client/pages/credit/order/index.vue' /* webpackChunkName: "pages/credit/order/index" */))
const _6b232fa8 = () => interopDefault(import('../client/pages/credit/pay/index.vue' /* webpackChunkName: "pages/credit/pay/index" */))
const _7e014446 = () => interopDefault(import('../client/pages/credit/report/index.vue' /* webpackChunkName: "pages/credit/report/index" */))
const _524caa2a = () => interopDefault(import('../client/pages/credit/pay/detail/index.vue' /* webpackChunkName: "pages/credit/pay/detail/index" */))
const _342e4660 = () => interopDefault(import('../client/pages/credit/report/detail/index.vue' /* webpackChunkName: "pages/credit/report/detail/index" */))
const _3e1141e1 = () => interopDefault(import('../client/pages/credit/report/example/index.vue' /* webpackChunkName: "pages/credit/report/example/index" */))
const _65c985ec = () => interopDefault(import('../client/pages/credit/order/detail/_id.vue' /* webpackChunkName: "pages/credit/order/detail/_id" */))
const _a0ea1e22 = () => interopDefault(import('../client/pages/finace.vue' /* webpackChunkName: "pages/finace" */))
const _a3566f9c = () => interopDefault(import('../client/pages/finace/index.vue' /* webpackChunkName: "pages/finace/index" */))
const _660ad5e4 = () => interopDefault(import('../client/pages/finace/bill-manage/index.vue' /* webpackChunkName: "pages/finace/bill-manage/index" */))
const _679f1e53 = () => interopDefault(import('../client/pages/finace/bill-manage/index/bill-client/index.vue' /* webpackChunkName: "pages/finace/bill-manage/index/bill-client/index" */))
const _0db1f79a = () => interopDefault(import('../client/pages/finace/bill-manage/index/bill-contract/index.vue' /* webpackChunkName: "pages/finace/bill-manage/index/bill-contract/index" */))
const _335d97fe = () => interopDefault(import('../client/pages/finace/bill-manage/index/bill-credit/index.vue' /* webpackChunkName: "pages/finace/bill-manage/index/bill-credit/index" */))
const _b5fe8058 = () => interopDefault(import('../client/pages/finace/bill-manage/index/bill-supplier/index.vue' /* webpackChunkName: "pages/finace/bill-manage/index/bill-supplier/index" */))
const _68510a23 = () => interopDefault(import('../client/pages/finace/bill-manage/index/bill-client/detail/index.vue' /* webpackChunkName: "pages/finace/bill-manage/index/bill-client/detail/index" */))
const _4f08fa08 = () => interopDefault(import('../client/pages/finace/bill-manage/index/bill-contract/detail/index.vue' /* webpackChunkName: "pages/finace/bill-manage/index/bill-contract/detail/index" */))
const _7119d4b5 = () => interopDefault(import('../client/pages/finace/bill-manage/index/bill-credit/detail/index.vue' /* webpackChunkName: "pages/finace/bill-manage/index/bill-credit/detail/index" */))
const _17e61709 = () => interopDefault(import('../client/pages/finace/bill-manage/index/bill-credit/import/index.vue' /* webpackChunkName: "pages/finace/bill-manage/index/bill-credit/import/index" */))
const _fd0034fc = () => interopDefault(import('../client/pages/finace/bill-manage/index/bill-supplier/detail/index.vue' /* webpackChunkName: "pages/finace/bill-manage/index/bill-supplier/detail/index" */))
const _5e7f77de = () => interopDefault(import('../client/pages/finace/customer-account/index.vue' /* webpackChunkName: "pages/finace/customer-account/index" */))
const _2f71da88 = () => interopDefault(import('../client/pages/finace/income/index.vue' /* webpackChunkName: "pages/finace/income/index" */))
const _72d7fa4d = () => interopDefault(import('../client/pages/finace/customer-account/detail/_id.vue' /* webpackChunkName: "pages/finace/customer-account/detail/_id" */))
const _c6ff110e = () => interopDefault(import('../client/pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _7550c25f = () => interopDefault(import('../client/pages/merchant.vue' /* webpackChunkName: "pages/merchant" */))
const _6773c5a2 = () => interopDefault(import('../client/pages/merchant/index.vue' /* webpackChunkName: "pages/merchant/index" */))
const _9266ebfc = () => interopDefault(import('../client/pages/merchant/basesets.vue' /* webpackChunkName: "pages/merchant/basesets" */))
const _07560705 = () => interopDefault(import('../client/pages/merchant/basesets/index.vue' /* webpackChunkName: "pages/merchant/basesets/index" */))
const _f4d087be = () => interopDefault(import('../client/pages/merchant/basesets/companyinfo/index.vue' /* webpackChunkName: "pages/merchant/basesets/companyinfo/index" */))
const _7dd1a618 = () => interopDefault(import('../client/pages/merchant/basesets/companymanage/index.vue' /* webpackChunkName: "pages/merchant/basesets/companymanage/index" */))
const _4a61cb13 = () => interopDefault(import('../client/pages/merchant/basesets/employeeManage/index.vue' /* webpackChunkName: "pages/merchant/basesets/employeeManage/index" */))
const _75105e9d = () => interopDefault(import('../client/pages/merchant/basesets/operationlog/index.vue' /* webpackChunkName: "pages/merchant/basesets/operationlog/index" */))
const _3aff5b3b = () => interopDefault(import('../client/pages/merchant/basesets/rolemanage/index.vue' /* webpackChunkName: "pages/merchant/basesets/rolemanage/index" */))
const _3d9d06e0 = () => interopDefault(import('../client/pages/merchant/basesets/usermanage/index.vue' /* webpackChunkName: "pages/merchant/basesets/usermanage/index" */))
const _bd2fbb26 = () => interopDefault(import('../client/pages/merchant/basesets/employeeManage/rolemanage/index.vue' /* webpackChunkName: "pages/merchant/basesets/employeeManage/rolemanage/index" */))
const _479a43c2 = () => interopDefault(import('../client/pages/merchant/basesets/employeeManage/usermanage/index.vue' /* webpackChunkName: "pages/merchant/basesets/employeeManage/usermanage/index" */))
const _11703934 = () => interopDefault(import('../client/pages/merchant/basesets/usermanage/employeeapproval/index.vue' /* webpackChunkName: "pages/merchant/basesets/usermanage/employeeapproval/index" */))
const _0db7b318 = () => interopDefault(import('../client/pages/merchant/basesets/employeeManage/usermanage/employeeapproval/index.vue' /* webpackChunkName: "pages/merchant/basesets/employeeManage/usermanage/employeeapproval/index" */))
const _305ef8e7 = () => interopDefault(import('../client/pages/merchant/basesets/employeeManage/rolemanage/roledetail/_id.vue' /* webpackChunkName: "pages/merchant/basesets/employeeManage/rolemanage/roledetail/_id" */))
const _0a8e9a32 = () => interopDefault(import('../client/pages/merchant/basesets/employeeManage/rolemanage/roleset/_id.vue' /* webpackChunkName: "pages/merchant/basesets/employeeManage/rolemanage/roleset/_id" */))
const _147fc7d9 = () => interopDefault(import('../client/pages/merchant/basesets/rolemanage/roledetail/_id.vue' /* webpackChunkName: "pages/merchant/basesets/rolemanage/roledetail/_id" */))
const _75c0ff00 = () => interopDefault(import('../client/pages/merchant/basesets/rolemanage/roleset/_id.vue' /* webpackChunkName: "pages/merchant/basesets/rolemanage/roleset/_id" */))
const _4a32a7c0 = () => interopDefault(import('../client/pages/merchant/config-manage.vue' /* webpackChunkName: "pages/merchant/config-manage" */))
const _7e96e6ea = () => interopDefault(import('../client/pages/merchant/config-manage/check-vehicle-config/index.vue' /* webpackChunkName: "pages/merchant/config-manage/check-vehicle-config/index" */))
const _e1e42250 = () => interopDefault(import('../client/pages/merchant/config-manage/contract-fee-config/index.vue' /* webpackChunkName: "pages/merchant/config-manage/contract-fee-config/index" */))
const _0da5e1bc = () => interopDefault(import('../client/pages/merchant/config-manage/contract-template-config/index.vue' /* webpackChunkName: "pages/merchant/config-manage/contract-template-config/index" */))
const _9ce1e2ac = () => interopDefault(import('../client/pages/merchant/config-manage/rent-config/index.vue' /* webpackChunkName: "pages/merchant/config-manage/rent-config/index" */))
const _05ac3f2a = () => interopDefault(import('../client/pages/merchant/config-manage/verify-config/index.vue' /* webpackChunkName: "pages/merchant/config-manage/verify-config/index" */))
const _2a39dfbb = () => interopDefault(import('../client/pages/merchant/config-manage/contract-template-config/edit/_id.vue' /* webpackChunkName: "pages/merchant/config-manage/contract-template-config/edit/_id" */))
const _230b7ac8 = () => interopDefault(import('../client/pages/merchant/service/index.vue' /* webpackChunkName: "pages/merchant/service/index" */))
const _1eb18daf = () => interopDefault(import('../client/pages/merchant/supplier/index.vue' /* webpackChunkName: "pages/merchant/supplier/index" */))
const _1170f494 = () => interopDefault(import('../client/pages/merchant/service/company/index.vue' /* webpackChunkName: "pages/merchant/service/company/index" */))
const _4386a364 = () => interopDefault(import('../client/pages/merchant/service/companyinfo/index.vue' /* webpackChunkName: "pages/merchant/service/companyinfo/index" */))
const _5e278015 = () => interopDefault(import('../client/pages/merchant/service/credit-intro/index.vue' /* webpackChunkName: "pages/merchant/service/credit-intro/index" */))
const _2e566411 = () => interopDefault(import('../client/pages/merchant/service/econtract-apply/index.vue' /* webpackChunkName: "pages/merchant/service/econtract-apply/index" */))
const _50bd218f = () => interopDefault(import('../client/pages/merchant/service/econtract-intro/index.vue' /* webpackChunkName: "pages/merchant/service/econtract-intro/index" */))
const _a2fa41be = () => interopDefault(import('../client/pages/merchant/service/pay/index.vue' /* webpackChunkName: "pages/merchant/service/pay/index" */))
const _05219240 = () => interopDefault(import('../client/pages/merchant/service/pay-intro/index.vue' /* webpackChunkName: "pages/merchant/service/pay-intro/index" */))
const _d9efeb86 = () => interopDefault(import('../client/pages/merchant/service/violation-intro/index.vue' /* webpackChunkName: "pages/merchant/service/violation-intro/index" */))
const _b442df00 = () => interopDefault(import('../client/pages/merchant/service/pay/apply/index.vue' /* webpackChunkName: "pages/merchant/service/pay/apply/index" */))
const _7d5f40c1 = () => interopDefault(import('../client/pages/merchant/service/pay/bind/index.vue' /* webpackChunkName: "pages/merchant/service/pay/bind/index" */))
const _5a2bf922 = () => interopDefault(import('../client/pages/merchant/supplier/detail/_id.vue' /* webpackChunkName: "pages/merchant/supplier/detail/_id" */))
const _9009be6c = () => interopDefault(import('../client/pages/organization.vue' /* webpackChunkName: "pages/organization" */))
const _57370ad0 = () => interopDefault(import('../client/pages/rent.vue' /* webpackChunkName: "pages/rent" */))
const _41b7955a = () => interopDefault(import('../client/pages/rent/index.vue' /* webpackChunkName: "pages/rent/index" */))
const _01ccc788 = () => interopDefault(import('../client/pages/rent/car-team/index.vue' /* webpackChunkName: "pages/rent/car-team/index" */))
const _4448e684 = () => interopDefault(import('../client/pages/rent/contract-manage/index.vue' /* webpackChunkName: "pages/rent/contract-manage/index" */))
const _3b196316 = () => interopDefault(import('../client/pages/rent/customer-manage.vue' /* webpackChunkName: "pages/rent/customer-manage" */))
const _67466938 = () => interopDefault(import('../client/pages/rent/customer-manage/index.vue' /* webpackChunkName: "pages/rent/customer-manage/index" */))
const _42b5f7b4 = () => interopDefault(import('../client/pages/rent/customer-manage/company/index.vue' /* webpackChunkName: "pages/rent/customer-manage/company/index" */))
const _c5bcfa96 = () => interopDefault(import('../client/pages/rent/customer-manage/driver/index.vue' /* webpackChunkName: "pages/rent/customer-manage/driver/index" */))
const _126627ac = () => interopDefault(import('../client/pages/rent/customer-manage/company/addedit/_id.vue' /* webpackChunkName: "pages/rent/customer-manage/company/addedit/_id" */))
const _39c0ff58 = () => interopDefault(import('../client/pages/rent/customer-manage/company/detail/_id.vue' /* webpackChunkName: "pages/rent/customer-manage/company/detail/_id" */))
const _73e3f90e = () => interopDefault(import('../client/pages/rent/customer-manage/driver/addedit/_id.vue' /* webpackChunkName: "pages/rent/customer-manage/driver/addedit/_id" */))
const _1f684229 = () => interopDefault(import('../client/pages/rent/customer-manage/driver/detail/_id.vue' /* webpackChunkName: "pages/rent/customer-manage/driver/detail/_id" */))
const _6591f878 = () => interopDefault(import('../client/pages/rent/decrease-manage/index.vue' /* webpackChunkName: "pages/rent/decrease-manage/index" */))
const _14a2e5e6 = () => interopDefault(import('../client/pages/rent/econtract-manage/index.vue' /* webpackChunkName: "pages/rent/econtract-manage/index" */))
const _5bcaa576 = () => interopDefault(import('../client/pages/rent/contract-manage/accountancy/index.vue' /* webpackChunkName: "pages/rent/contract-manage/accountancy/index" */))
const _48df2eec = () => interopDefault(import('../client/pages/rent/contract-manage/car-order/index.vue' /* webpackChunkName: "pages/rent/contract-manage/car-order/index" */))
const _082b7572 = () => interopDefault(import('../client/pages/rent/contract-manage/company/index.vue' /* webpackChunkName: "pages/rent/contract-manage/company/index" */))
const _79dac46c = () => interopDefault(import('../client/pages/rent/contract-manage/contract-operate-record/index.vue' /* webpackChunkName: "pages/rent/contract-manage/contract-operate-record/index" */))
const _43a1b7e9 = () => interopDefault(import('../client/pages/rent/contract-manage/driver/index.vue' /* webpackChunkName: "pages/rent/contract-manage/driver/index" */))
const _89a1dd3a = () => interopDefault(import('../client/pages/rent/decrease-manage/batch/index.vue' /* webpackChunkName: "pages/rent/decrease-manage/batch/index" */))
const _0c3f2744 = () => interopDefault(import('../client/pages/rent/decrease-manage/detail/index.vue' /* webpackChunkName: "pages/rent/decrease-manage/detail/index" */))
const _e7ebb32c = () => interopDefault(import('../client/pages/rent/econtract-manage/contract/index.vue' /* webpackChunkName: "pages/rent/econtract-manage/contract/index" */))
const _328dc94c = () => interopDefault(import('../client/pages/rent/econtract-manage/order/index.vue' /* webpackChunkName: "pages/rent/econtract-manage/order/index" */))
const _5726f268 = () => interopDefault(import('../client/pages/rent/contract-manage/contract-operate-record/detail/index.vue' /* webpackChunkName: "pages/rent/contract-manage/contract-operate-record/detail/index" */))
const _4faeef0a = () => interopDefault(import('../client/pages/rent/contract-manage/driver/add/index.vue' /* webpackChunkName: "pages/rent/contract-manage/driver/add/index" */))
const _7c786308 = () => interopDefault(import('../client/pages/rent/econtract-manage/contract/add/index.vue' /* webpackChunkName: "pages/rent/econtract-manage/contract/add/index" */))
const _01c1b4cf = () => interopDefault(import('../client/pages/rent/econtract-manage/contract/charge/index.vue' /* webpackChunkName: "pages/rent/econtract-manage/contract/charge/index" */))
const _6fd57944 = () => interopDefault(import('../client/pages/rent/econtract-manage/contract/success/index.vue' /* webpackChunkName: "pages/rent/econtract-manage/contract/success/index" */))
const _75e520a6 = () => interopDefault(import('../client/pages/rent/econtract-manage/order/charge/index.vue' /* webpackChunkName: "pages/rent/econtract-manage/order/charge/index" */))
const _b211cdec = () => interopDefault(import('../client/pages/rent/econtract-manage/order/record/index.vue' /* webpackChunkName: "pages/rent/econtract-manage/order/record/index" */))
const _0fc57336 = () => interopDefault(import('../client/pages/rent/econtract-manage/order/seal/index.vue' /* webpackChunkName: "pages/rent/econtract-manage/order/seal/index" */))
const _0c81e1a6 = () => interopDefault(import('../client/pages/rent/contract-manage/accountancy/liquidation-detail/_id.vue' /* webpackChunkName: "pages/rent/contract-manage/accountancy/liquidation-detail/_id" */))
const _15c91797 = () => interopDefault(import('../client/pages/rent/contract-manage/accountancy/liquidation/_id.vue' /* webpackChunkName: "pages/rent/contract-manage/accountancy/liquidation/_id" */))
const _be57a4da = () => interopDefault(import('../client/pages/rent/contract-manage/accountancy/settlement-detail/_id.vue' /* webpackChunkName: "pages/rent/contract-manage/accountancy/settlement-detail/_id" */))
const _411e801e = () => interopDefault(import('../client/pages/rent/contract-manage/accountancy/settlement/_id.vue' /* webpackChunkName: "pages/rent/contract-manage/accountancy/settlement/_id" */))
const _320e8d18 = () => interopDefault(import('../client/pages/rent/contract-manage/car-order/detail/_id.vue' /* webpackChunkName: "pages/rent/contract-manage/car-order/detail/_id" */))
const _73676b37 = () => interopDefault(import('../client/pages/rent/contract-manage/driver/carryout/_id.vue' /* webpackChunkName: "pages/rent/contract-manage/driver/carryout/_id" */))
const _527d2d75 = () => interopDefault(import('../client/pages/rent/contract-manage/driver/detail/_id.vue' /* webpackChunkName: "pages/rent/contract-manage/driver/detail/_id" */))
const _2d2a52fb = () => interopDefault(import('../client/pages/rent/contract-manage/driver/release-detail/_id.vue' /* webpackChunkName: "pages/rent/contract-manage/driver/release-detail/_id" */))
const _c76ab0d8 = () => interopDefault(import('../client/pages/rent/econtract-manage/contract/detail/_id.vue' /* webpackChunkName: "pages/rent/econtract-manage/contract/detail/_id" */))
const _fee39766 = () => interopDefault(import('../client/pages/rent/econtract-manage/contract/edit/_id.vue' /* webpackChunkName: "pages/rent/econtract-manage/contract/edit/_id" */))
const _728f4100 = () => interopDefault(import('../client/pages/rent/econtract-manage/contract/sign/_id.vue' /* webpackChunkName: "pages/rent/econtract-manage/contract/sign/_id" */))
const _fb38fc1c = () => interopDefault(import('../client/pages/rent/econtract-manage/order/detail/_id.vue' /* webpackChunkName: "pages/rent/econtract-manage/order/detail/_id" */))
const _35db590a = () => interopDefault(import('../client/pages/reports/index.vue' /* webpackChunkName: "pages/reports/index" */))
const _6406b09f = () => interopDefault(import('../client/pages/single.vue' /* webpackChunkName: "pages/single" */))
const _6f38fb34 = () => interopDefault(import('../client/pages/single/business-report/index.vue' /* webpackChunkName: "pages/single/business-report/index" */))
const _70295f3a = () => interopDefault(import('../client/pages/supplier.vue' /* webpackChunkName: "pages/supplier" */))
const _6704c0a6 = () => interopDefault(import('../client/pages/supplier/index.vue' /* webpackChunkName: "pages/supplier/index" */))
const _a0aa3a00 = () => interopDefault(import('../client/pages/supplier/authorize/index.vue' /* webpackChunkName: "pages/supplier/authorize/index" */))
const _2b2973f4 = () => interopDefault(import('../client/pages/supplier/authorize/apply-files.vue' /* webpackChunkName: "pages/supplier/authorize/apply-files" */))
const _5aed8e22 = () => interopDefault(import('../client/pages/supplier/authorize/apply-info.vue' /* webpackChunkName: "pages/supplier/authorize/apply-info" */))
const _48fcc919 = () => interopDefault(import('../client/pages/supplier/authorize/apply-submit.vue' /* webpackChunkName: "pages/supplier/authorize/apply-submit" */))
const _43f6e6da = () => interopDefault(import('../client/pages/supplier/authorize/detail.vue' /* webpackChunkName: "pages/supplier/authorize/detail" */))
const _36adeddd = () => interopDefault(import('../client/pages/supplier/authorize/document.vue' /* webpackChunkName: "pages/supplier/authorize/document" */))
const _13bec39a = () => interopDefault(import('../client/pages/supplier/authorize/intro.vue' /* webpackChunkName: "pages/supplier/authorize/intro" */))
const _aa76dfcc = () => interopDefault(import('../client/pages/supplier/authorize/review.vue' /* webpackChunkName: "pages/supplier/authorize/review" */))
const _53e35306 = () => interopDefault(import('../client/pages/system.vue' /* webpackChunkName: "pages/system" */))
const _d980b436 = () => interopDefault(import('../client/pages/vehicle.vue' /* webpackChunkName: "pages/vehicle" */))
const _9c2126b0 = () => interopDefault(import('../client/pages/vehicle/index.vue' /* webpackChunkName: "pages/vehicle/index" */))
const _5c550c7c = () => interopDefault(import('../client/pages/vehicle/accident-manage/index.vue' /* webpackChunkName: "pages/vehicle/accident-manage/index" */))
const _26db03a8 = () => interopDefault(import('../client/pages/vehicle/annual-manage/index.vue' /* webpackChunkName: "pages/vehicle/annual-manage/index" */))
const _885666b2 = () => interopDefault(import('../client/pages/vehicle/break-rule/index.vue' /* webpackChunkName: "pages/vehicle/break-rule/index" */))
const _178bac67 = () => interopDefault(import('../client/pages/vehicle/guarantee-manage/index.vue' /* webpackChunkName: "pages/vehicle/guarantee-manage/index" */))
const _7f263ae7 = () => interopDefault(import('../client/pages/vehicle/license-tag/index.vue' /* webpackChunkName: "pages/vehicle/license-tag/index" */))
const _7fa49080 = () => interopDefault(import('../client/pages/vehicle/maintain-manage/index.vue' /* webpackChunkName: "pages/vehicle/maintain-manage/index" */))
const _3bbc2457 = () => interopDefault(import('../client/pages/vehicle/out-in-warehouse/index.vue' /* webpackChunkName: "pages/vehicle/out-in-warehouse/index" */))
const _2875a23e = () => interopDefault(import('../client/pages/vehicle/repair-manage/index.vue' /* webpackChunkName: "pages/vehicle/repair-manage/index" */))
const _9ce78b1a = () => interopDefault(import('../client/pages/vehicle/traffic-declare/index.vue' /* webpackChunkName: "pages/vehicle/traffic-declare/index" */))
const _0ac227d2 = () => interopDefault(import('../client/pages/vehicle/validate-vehicle/index.vue' /* webpackChunkName: "pages/vehicle/validate-vehicle/index" */))
const _73859c57 = () => interopDefault(import('../client/pages/vehicle/vehicle-dispose/index.vue' /* webpackChunkName: "pages/vehicle/vehicle-dispose/index" */))
const _137eee72 = () => interopDefault(import('../client/pages/vehicle/vehicle-groups/index.vue' /* webpackChunkName: "pages/vehicle/vehicle-groups/index" */))
const _770d5a40 = () => interopDefault(import('../client/pages/vehicle/vehicle-manage.vue' /* webpackChunkName: "pages/vehicle/vehicle-manage" */))
const _777d30c3 = () => interopDefault(import('../client/pages/vehicle/vehicle-manage/index.vue' /* webpackChunkName: "pages/vehicle/vehicle-manage/index" */))
const _06019ea3 = () => interopDefault(import('../client/pages/vehicle/vehicle-manage/common-use-model/index.vue' /* webpackChunkName: "pages/vehicle/vehicle-manage/common-use-model/index" */))
const _9b045c28 = () => interopDefault(import('../client/pages/vehicle/vehicle-manage/edit/index.vue' /* webpackChunkName: "pages/vehicle/vehicle-manage/edit/index" */))
const _44cce344 = () => interopDefault(import('../client/pages/vehicle/vehicle-manage/plate-manage/index.vue' /* webpackChunkName: "pages/vehicle/vehicle-manage/plate-manage/index" */))
const _156fd57e = () => interopDefault(import('../client/pages/vehicle/vehicle-manage/vehicle-list/index.vue' /* webpackChunkName: "pages/vehicle/vehicle-manage/vehicle-list/index" */))
const _2ae40c2e = () => interopDefault(import('../client/pages/vehicle/vehicle-manage/vehicle-list/edit/index.vue' /* webpackChunkName: "pages/vehicle/vehicle-manage/vehicle-list/edit/index" */))
const _4fa21820 = () => interopDefault(import('../client/pages/vehicle/vehicle-manage/plate-manage/detail/_id.vue' /* webpackChunkName: "pages/vehicle/vehicle-manage/plate-manage/detail/_id" */))
const _0173671d = () => interopDefault(import('../client/pages/vehicle/vehicle-manage/vehicle-list/detail/_id.vue' /* webpackChunkName: "pages/vehicle/vehicle-manage/vehicle-list/detail/_id" */))
const _ede5a64a = () => interopDefault(import('../client/pages/vehicle/vehicle-manage/detail/_id.vue' /* webpackChunkName: "pages/vehicle/vehicle-manage/detail/_id" */))
const _07295b8e = () => interopDefault(import('../client/pages/vehicle/accident-manage/add/index.vue' /* webpackChunkName: "pages/vehicle/accident-manage/add/index" */))
const _ae78e84c = () => interopDefault(import('../client/pages/vehicle/accident-manage/detail/index.vue' /* webpackChunkName: "pages/vehicle/accident-manage/detail/index" */))
const _72f6753e = () => interopDefault(import('../client/pages/vehicle/annual-manage/add/index.vue' /* webpackChunkName: "pages/vehicle/annual-manage/add/index" */))
const _1acdb09c = () => interopDefault(import('../client/pages/vehicle/annual-manage/annual-record/index.vue' /* webpackChunkName: "pages/vehicle/annual-manage/annual-record/index" */))
const _ccf857ac = () => interopDefault(import('../client/pages/vehicle/annual-manage/detail/index.vue' /* webpackChunkName: "pages/vehicle/annual-manage/detail/index" */))
const _5b3957d2 = () => interopDefault(import('../client/pages/vehicle/break-rule/break-query/index.vue' /* webpackChunkName: "pages/vehicle/break-rule/break-query/index" */))
const _494a75c6 = () => interopDefault(import('../client/pages/vehicle/break-rule/home/index.vue' /* webpackChunkName: "pages/vehicle/break-rule/home/index" */))
const _f7248d32 = () => interopDefault(import('../client/pages/vehicle/break-rule/manual-search/index.vue' /* webpackChunkName: "pages/vehicle/break-rule/manual-search/index" */))
const _0061a94f = () => interopDefault(import('../client/pages/vehicle/break-rule/record/index.vue' /* webpackChunkName: "pages/vehicle/break-rule/record/index" */))
const _7eeff9a4 = () => interopDefault(import('../client/pages/vehicle/break-rule/search-record/index.vue' /* webpackChunkName: "pages/vehicle/break-rule/search-record/index" */))
const _04e80df9 = () => interopDefault(import('../client/pages/vehicle/guarantee-manage/add/index.vue' /* webpackChunkName: "pages/vehicle/guarantee-manage/add/index" */))
const _3b603d8f = () => interopDefault(import('../client/pages/vehicle/guarantee-manage/detail/index.vue' /* webpackChunkName: "pages/vehicle/guarantee-manage/detail/index" */))
const _379ad78a = () => interopDefault(import('../client/pages/vehicle/guarantee-manage/guarantee-files/index.vue' /* webpackChunkName: "pages/vehicle/guarantee-manage/guarantee-files/index" */))
const _dad7cb18 = () => interopDefault(import('../client/pages/vehicle/guarantee-manage/guarantee-record/index.vue' /* webpackChunkName: "pages/vehicle/guarantee-manage/guarantee-record/index" */))
const _2f4f3d92 = () => interopDefault(import('../client/pages/vehicle/maintain-manage/add/index.vue' /* webpackChunkName: "pages/vehicle/maintain-manage/add/index" */))
const _8d878954 = () => interopDefault(import('../client/pages/vehicle/maintain-manage/detail/index.vue' /* webpackChunkName: "pages/vehicle/maintain-manage/detail/index" */))
const _71ae1c44 = () => interopDefault(import('../client/pages/vehicle/maintain-manage/maintain-record/index.vue' /* webpackChunkName: "pages/vehicle/maintain-manage/maintain-record/index" */))
const _e3617884 = () => interopDefault(import('../client/pages/vehicle/maintain-manage/record-detail/index.vue' /* webpackChunkName: "pages/vehicle/maintain-manage/record-detail/index" */))
const _01a54050 = () => interopDefault(import('../client/pages/vehicle/repair-manage/add/index.vue' /* webpackChunkName: "pages/vehicle/repair-manage/add/index" */))
const _7f355050 = () => interopDefault(import('../client/pages/vehicle/repair-manage/detail/index.vue' /* webpackChunkName: "pages/vehicle/repair-manage/detail/index" */))
const _26d20a03 = () => interopDefault(import('../client/pages/vehicle/traffic-declare/detail/index.vue' /* webpackChunkName: "pages/vehicle/traffic-declare/detail/index" */))
const _1ceef42e = () => interopDefault(import('../client/pages/vehicle/vehicle-dispose/add/index.vue' /* webpackChunkName: "pages/vehicle/vehicle-dispose/add/index" */))
const _7d7500c2 = () => interopDefault(import('../client/pages/vehicle/vehicle-dispose/detail/index.vue' /* webpackChunkName: "pages/vehicle/vehicle-dispose/detail/index" */))
const _3cebf112 = () => interopDefault(import('../client/pages/vehicle/break-rule/home/break-explain/index.vue' /* webpackChunkName: "pages/vehicle/break-rule/home/break-explain/index" */))
const _f4f7769e = () => interopDefault(import('../client/pages/vehicle/break-rule/home/support-city/index.vue' /* webpackChunkName: "pages/vehicle/break-rule/home/support-city/index" */))
const _668c2550 = () => interopDefault(import('../client/pages/vehicle/break-rule/search-record/detail/_id.vue' /* webpackChunkName: "pages/vehicle/break-rule/search-record/detail/_id" */))
const _30e8d937 = () => interopDefault(import('../client/pages/vehicle/license-tag/detail/_id.vue' /* webpackChunkName: "pages/vehicle/license-tag/detail/_id" */))
const _63c43cc8 = () => interopDefault(import('../client/pages/vehicle/out-in-warehouse/contract/_id.vue' /* webpackChunkName: "pages/vehicle/out-in-warehouse/contract/_id" */))
const _61bb55c7 = () => interopDefault(import('../client/pages/vehicle/out-in-warehouse/detail/_id.vue' /* webpackChunkName: "pages/vehicle/out-in-warehouse/detail/_id" */))
const _50e617fa = () => interopDefault(import('../client/pages/vehicle/out-in-warehouse/form/_id.vue' /* webpackChunkName: "pages/vehicle/out-in-warehouse/form/_id" */))
const _3607b32c = () => interopDefault(import('../client/pages/vehicle/out-in-warehouse/inspection/_id.vue' /* webpackChunkName: "pages/vehicle/out-in-warehouse/inspection/_id" */))
const _123971a8 = () => interopDefault(import('../client/pages/vehicle/validate-vehicle/detail/_id.vue' /* webpackChunkName: "pages/vehicle/validate-vehicle/detail/_id" */))
const _14493fb8 = () => interopDefault(import('../client/pages/vehicle/vehicle-groups/save/_id.vue' /* webpackChunkName: "pages/vehicle/vehicle-groups/save/_id" */))
const _3c4b816f = () => interopDefault(import('../client/pages/home/approval/index.vue' /* webpackChunkName: "pages/home/approval/index" */))
const _5ebb0a87 = () => interopDefault(import('../client/pages/home/approval/index/approved.vue' /* webpackChunkName: "pages/home/approval/index/approved" */))
const _4801b783 = () => interopDefault(import('../client/pages/home/approval/index/launch.vue' /* webpackChunkName: "pages/home/approval/index/launch" */))
const _6b58c998 = () => interopDefault(import('../client/pages/home/approval/index/monitoring.vue' /* webpackChunkName: "pages/home/approval/index/monitoring" */))
const _0ae7fcf7 = () => interopDefault(import('../client/pages/home/approval/index/pending.vue' /* webpackChunkName: "pages/home/approval/index/pending" */))
const _edc7ab50 = () => interopDefault(import('../client/pages/home/my/index.vue' /* webpackChunkName: "pages/home/my/index" */))
const _b38cbaa0 = () => interopDefault(import('../client/pages/home/my-task/index.vue' /* webpackChunkName: "pages/home/my-task/index" */))
const _d16c7b02 = () => interopDefault(import('../client/pages/home/news/index.vue' /* webpackChunkName: "pages/home/news/index" */))
const _5e487c04 = () => interopDefault(import('../client/pages/home/notice/index.vue' /* webpackChunkName: "pages/home/notice/index" */))
const _b70b0aee = () => interopDefault(import('../client/pages/home/operationLog/index.vue' /* webpackChunkName: "pages/home/operationLog/index" */))
const _bfa0f6d0 = () => interopDefault(import('../client/pages/home/portal/index.vue' /* webpackChunkName: "pages/home/portal/index" */))
const _0b37ec17 = () => interopDefault(import('../client/pages/home/task-manage/index.vue' /* webpackChunkName: "pages/home/task-manage/index" */))
const _ca0766f8 = () => interopDefault(import('../client/pages/reports/data-center/index.vue' /* webpackChunkName: "pages/reports/data-center/index" */))
const _48f81e1e = () => interopDefault(import('../client/pages/reports/risk-report/index.vue' /* webpackChunkName: "pages/reports/risk-report/index" */))
const _1cf5d95c = () => interopDefault(import('../client/pages/home/notice/detail/index.vue' /* webpackChunkName: "pages/home/notice/detail/index" */))
const _55ccc9e5 = () => interopDefault(import('../client/pages/home/approval/detail/_detail.vue' /* webpackChunkName: "pages/home/approval/detail/_detail" */))
const _1842509f = () => interopDefault(import('../client/pages/home/news/detail/_id.vue' /* webpackChunkName: "pages/home/news/detail/_id" */))
const _4cefde6a = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

if (process.client) {
  window.history.scrollRestoration = 'manual'
}
const scrollBehavior = function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected
  if (to.matched.length < 2) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}

export function createRouter() {
  return new Router({
    mode: 'history',
    base: '/',
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/business",
      component: _2837fc57,
      children: [{
        path: "",
        component: _d954b4cc,
        name: "business"
      }, {
        path: "business-manage",
        component: _8ffea846,
        name: "business-business-manage"
      }, {
        path: "merchandise",
        component: _ca1d4364,
        name: "business-merchandise"
      }, {
        path: "subscribe-order",
        component: _19475cd4,
        name: "business-subscribe-order"
      }, {
        path: "action-manage/coupon-statistics",
        component: _79d8ef7c,
        name: "business-action-manage-coupon-statistics"
      }, {
        path: "action-manage/sale-action",
        component: _08d8b4d4,
        name: "business-action-manage-sale-action"
      }, {
        path: "business-manage/detail",
        component: _1de90d59,
        name: "business-business-manage-detail"
      }, {
        path: "action-manage/sale-action/add",
        component: _031dd034,
        name: "business-action-manage-sale-action-add"
      }, {
        path: "merchandise/detail/:id?",
        component: _255ebe30,
        name: "business-merchandise-detail-id"
      }, {
        path: "merchandise/save/:id?",
        component: _3270725c,
        name: "business-merchandise-save-id"
      }, {
        path: "subscribe-order/detail/:id",
        component: _b89ed030,
        name: "business-subscribe-order-detail-id"
      }]
    }, {
      path: "/credit",
      component: _0bef16d0,
      name: "credit",
      children: [{
        path: "account",
        component: _5be6fa1e,
        name: "credit-account"
      }, {
        path: "agreement",
        component: _0e5bf764,
        name: "credit-agreement"
      }, {
        path: "inquire",
        component: _750e52f7,
        name: "credit-inquire"
      }, {
        path: "order",
        component: _f9c09ddc,
        name: "credit-order"
      }, {
        path: "pay",
        component: _6b232fa8,
        name: "credit-pay"
      }, {
        path: "report",
        component: _7e014446,
        name: "credit-report"
      }, {
        path: "pay/detail",
        component: _524caa2a,
        name: "credit-pay-detail"
      }, {
        path: "report/detail",
        component: _342e4660,
        name: "credit-report-detail"
      }, {
        path: "report/example",
        component: _3e1141e1,
        name: "credit-report-example"
      }, {
        path: "order/detail/:id?",
        component: _65c985ec,
        name: "credit-order-detail-id"
      }]
    }, {
      path: "/finace",
      component: _a0ea1e22,
      children: [{
        path: "",
        component: _a3566f9c,
        name: "finace"
      }, {
        path: "bill-manage",
        component: _660ad5e4,
        name: "finace-bill-manage",
        children: [{
          path: "bill-client",
          component: _679f1e53,
          name: "finace-bill-manage-index-bill-client"
        }, {
          path: "bill-contract",
          component: _0db1f79a,
          name: "finace-bill-manage-index-bill-contract"
        }, {
          path: "bill-credit",
          component: _335d97fe,
          name: "finace-bill-manage-index-bill-credit"
        }, {
          path: "bill-supplier",
          component: _b5fe8058,
          name: "finace-bill-manage-index-bill-supplier"
        }, {
          path: "bill-client/detail",
          component: _68510a23,
          name: "finace-bill-manage-index-bill-client-detail"
        }, {
          path: "bill-contract/detail",
          component: _4f08fa08,
          name: "finace-bill-manage-index-bill-contract-detail"
        }, {
          path: "bill-credit/detail",
          component: _7119d4b5,
          name: "finace-bill-manage-index-bill-credit-detail"
        }, {
          path: "bill-credit/import",
          component: _17e61709,
          name: "finace-bill-manage-index-bill-credit-import"
        }, {
          path: "bill-supplier/detail",
          component: _fd0034fc,
          name: "finace-bill-manage-index-bill-supplier-detail"
        }]
      }, {
        path: "customer-account",
        component: _5e7f77de,
        name: "finace-customer-account"
      }, {
        path: "income",
        component: _2f71da88,
        name: "finace-income"
      }, {
        path: "customer-account/detail/:id",
        component: _72d7fa4d,
        name: "finace-customer-account-detail-id"
      }]
    }, {
      path: "/home",
      component: _c6ff110e,
      name: "home"
    }, {
      path: "/merchant",
      component: _7550c25f,
      children: [{
        path: "",
        component: _6773c5a2,
        name: "merchant"
      }, {
        path: "basesets",
        component: _9266ebfc,
        children: [{
          path: "",
          component: _07560705,
          name: "merchant-basesets"
        }, {
          path: "companyinfo",
          component: _f4d087be,
          name: "merchant-basesets-companyinfo"
        }, {
          path: "companymanage",
          component: _7dd1a618,
          name: "merchant-basesets-companymanage"
        }, {
          path: "employeeManage",
          component: _4a61cb13,
          name: "merchant-basesets-employeeManage"
        }, {
          path: "operationlog",
          component: _75105e9d,
          name: "merchant-basesets-operationlog"
        }, {
          path: "rolemanage",
          component: _3aff5b3b,
          name: "merchant-basesets-rolemanage"
        }, {
          path: "usermanage",
          component: _3d9d06e0,
          name: "merchant-basesets-usermanage"
        }, {
          path: "employeeManage/rolemanage",
          component: _bd2fbb26,
          name: "merchant-basesets-employeeManage-rolemanage"
        }, {
          path: "employeeManage/usermanage",
          component: _479a43c2,
          name: "merchant-basesets-employeeManage-usermanage"
        }, {
          path: "usermanage/employeeapproval",
          component: _11703934,
          name: "merchant-basesets-usermanage-employeeapproval"
        }, {
          path: "employeeManage/usermanage/employeeapproval",
          component: _0db7b318,
          name: "merchant-basesets-employeeManage-usermanage-employeeapproval"
        }, {
          path: "employeeManage/rolemanage/roledetail/:id",
          component: _305ef8e7,
          name: "merchant-basesets-employeeManage-rolemanage-roledetail-id"
        }, {
          path: "employeeManage/rolemanage/roleset/:id",
          component: _0a8e9a32,
          name: "merchant-basesets-employeeManage-rolemanage-roleset-id"
        }, {
          path: "rolemanage/roledetail/:id",
          component: _147fc7d9,
          name: "merchant-basesets-rolemanage-roledetail-id"
        }, {
          path: "rolemanage/roleset/:id",
          component: _75c0ff00,
          name: "merchant-basesets-rolemanage-roleset-id"
        }]
      }, {
        path: "config-manage",
        component: _4a32a7c0,
        name: "merchant-config-manage",
        children: [{
          path: "check-vehicle-config",
          component: _7e96e6ea,
          name: "merchant-config-manage-check-vehicle-config"
        }, {
          path: "contract-fee-config",
          component: _e1e42250,
          name: "merchant-config-manage-contract-fee-config"
        }, {
          path: "contract-template-config",
          component: _0da5e1bc,
          name: "merchant-config-manage-contract-template-config"
        }, {
          path: "rent-config",
          component: _9ce1e2ac,
          name: "merchant-config-manage-rent-config"
        }, {
          path: "verify-config",
          component: _05ac3f2a,
          name: "merchant-config-manage-verify-config"
        }, {
          path: "contract-template-config/edit/:id?",
          component: _2a39dfbb,
          name: "merchant-config-manage-contract-template-config-edit-id"
        }]
      }, {
        path: "service",
        component: _230b7ac8,
        name: "merchant-service"
      }, {
        path: "supplier",
        component: _1eb18daf,
        name: "merchant-supplier"
      }, {
        path: "service/company",
        component: _1170f494,
        name: "merchant-service-company"
      }, {
        path: "service/companyinfo",
        component: _4386a364,
        name: "merchant-service-companyinfo"
      }, {
        path: "service/credit-intro",
        component: _5e278015,
        name: "merchant-service-credit-intro"
      }, {
        path: "service/econtract-apply",
        component: _2e566411,
        name: "merchant-service-econtract-apply"
      }, {
        path: "service/econtract-intro",
        component: _50bd218f,
        name: "merchant-service-econtract-intro"
      }, {
        path: "service/pay",
        component: _a2fa41be,
        name: "merchant-service-pay"
      }, {
        path: "service/pay-intro",
        component: _05219240,
        name: "merchant-service-pay-intro"
      }, {
        path: "service/violation-intro",
        component: _d9efeb86,
        name: "merchant-service-violation-intro"
      }, {
        path: "service/pay/apply",
        component: _b442df00,
        name: "merchant-service-pay-apply"
      }, {
        path: "service/pay/bind",
        component: _7d5f40c1,
        name: "merchant-service-pay-bind"
      }, {
        path: "supplier/detail/:id?",
        component: _5a2bf922,
        name: "merchant-supplier-detail-id"
      }]
    }, {
      path: "/organization",
      component: _9009be6c,
      name: "organization"
    }, {
      path: "/rent",
      component: _57370ad0,
      children: [{
        path: "",
        component: _41b7955a,
        name: "rent"
      }, {
        path: "car-team",
        component: _01ccc788,
        name: "rent-car-team"
      }, {
        path: "contract-manage",
        component: _4448e684,
        name: "rent-contract-manage"
      }, {
        path: "customer-manage",
        component: _3b196316,
        children: [{
          path: "",
          component: _67466938,
          name: "rent-customer-manage"
        }, {
          path: "company",
          component: _42b5f7b4,
          name: "rent-customer-manage-company"
        }, {
          path: "driver",
          component: _c5bcfa96,
          name: "rent-customer-manage-driver"
        }, {
          path: "company/addedit/:id?",
          component: _126627ac,
          name: "rent-customer-manage-company-addedit-id"
        }, {
          path: "company/detail/:id?",
          component: _39c0ff58,
          name: "rent-customer-manage-company-detail-id"
        }, {
          path: "driver/addedit/:id?",
          component: _73e3f90e,
          name: "rent-customer-manage-driver-addedit-id"
        }, {
          path: "driver/detail/:id?",
          component: _1f684229,
          name: "rent-customer-manage-driver-detail-id"
        }]
      }, {
        path: "decrease-manage",
        component: _6591f878,
        name: "rent-decrease-manage"
      }, {
        path: "econtract-manage",
        component: _14a2e5e6,
        name: "rent-econtract-manage"
      }, {
        path: "contract-manage/accountancy",
        component: _5bcaa576,
        name: "rent-contract-manage-accountancy"
      }, {
        path: "contract-manage/car-order",
        component: _48df2eec,
        name: "rent-contract-manage-car-order"
      }, {
        path: "contract-manage/company",
        component: _082b7572,
        name: "rent-contract-manage-company"
      }, {
        path: "contract-manage/contract-operate-record",
        component: _79dac46c,
        name: "rent-contract-manage-contract-operate-record"
      }, {
        path: "contract-manage/driver",
        component: _43a1b7e9,
        name: "rent-contract-manage-driver"
      }, {
        path: "decrease-manage/batch",
        component: _89a1dd3a,
        name: "rent-decrease-manage-batch"
      }, {
        path: "decrease-manage/detail",
        component: _0c3f2744,
        name: "rent-decrease-manage-detail"
      }, {
        path: "econtract-manage/contract",
        component: _e7ebb32c,
        name: "rent-econtract-manage-contract"
      }, {
        path: "econtract-manage/order",
        component: _328dc94c,
        name: "rent-econtract-manage-order"
      }, {
        path: "contract-manage/contract-operate-record/detail",
        component: _5726f268,
        name: "rent-contract-manage-contract-operate-record-detail"
      }, {
        path: "contract-manage/driver/add",
        component: _4faeef0a,
        name: "rent-contract-manage-driver-add"
      }, {
        path: "econtract-manage/contract/add",
        component: _7c786308,
        name: "rent-econtract-manage-contract-add"
      }, {
        path: "econtract-manage/contract/charge",
        component: _01c1b4cf,
        name: "rent-econtract-manage-contract-charge"
      }, {
        path: "econtract-manage/contract/success",
        component: _6fd57944,
        name: "rent-econtract-manage-contract-success"
      }, {
        path: "econtract-manage/order/charge",
        component: _75e520a6,
        name: "rent-econtract-manage-order-charge"
      }, {
        path: "econtract-manage/order/record",
        component: _b211cdec,
        name: "rent-econtract-manage-order-record"
      }, {
        path: "econtract-manage/order/seal",
        component: _0fc57336,
        name: "rent-econtract-manage-order-seal"
      }, {
        path: "contract-manage/accountancy/liquidation-detail/:id",
        component: _0c81e1a6,
        name: "rent-contract-manage-accountancy-liquidation-detail-id"
      }, {
        path: "contract-manage/accountancy/liquidation/:id",
        component: _15c91797,
        name: "rent-contract-manage-accountancy-liquidation-id"
      }, {
        path: "contract-manage/accountancy/settlement-detail/:id",
        component: _be57a4da,
        name: "rent-contract-manage-accountancy-settlement-detail-id"
      }, {
        path: "contract-manage/accountancy/settlement/:id",
        component: _411e801e,
        name: "rent-contract-manage-accountancy-settlement-id"
      }, {
        path: "contract-manage/car-order/detail/:id",
        component: _320e8d18,
        name: "rent-contract-manage-car-order-detail-id"
      }, {
        path: "contract-manage/driver/carryout/:id",
        component: _73676b37,
        name: "rent-contract-manage-driver-carryout-id"
      }, {
        path: "contract-manage/driver/detail/:id",
        component: _527d2d75,
        name: "rent-contract-manage-driver-detail-id"
      }, {
        path: "contract-manage/driver/release-detail/:id",
        component: _2d2a52fb,
        name: "rent-contract-manage-driver-release-detail-id"
      }, {
        path: "econtract-manage/contract/detail/:id",
        component: _c76ab0d8,
        name: "rent-econtract-manage-contract-detail-id"
      }, {
        path: "econtract-manage/contract/edit/:id",
        component: _fee39766,
        name: "rent-econtract-manage-contract-edit-id"
      }, {
        path: "econtract-manage/contract/sign/:id",
        component: _728f4100,
        name: "rent-econtract-manage-contract-sign-id"
      }, {
        path: "econtract-manage/order/detail/:id",
        component: _fb38fc1c,
        name: "rent-econtract-manage-order-detail-id"
      }]
    }, {
      path: "/reports",
      component: _35db590a,
      name: "reports"
    }, {
      path: "/single",
      component: _6406b09f,
      name: "single",
      children: [{
        path: "business-report",
        component: _6f38fb34,
        name: "single-business-report"
      }]
    }, {
      path: "/supplier",
      component: _70295f3a,
      children: [{
        path: "",
        component: _6704c0a6,
        name: "supplier"
      }, {
        path: "authorize",
        component: _a0aa3a00,
        name: "supplier-authorize"
      }, {
        path: "authorize/apply-files",
        component: _2b2973f4,
        name: "supplier-authorize-apply-files"
      }, {
        path: "authorize/apply-info",
        component: _5aed8e22,
        name: "supplier-authorize-apply-info"
      }, {
        path: "authorize/apply-submit",
        component: _48fcc919,
        name: "supplier-authorize-apply-submit"
      }, {
        path: "authorize/detail",
        component: _43f6e6da,
        name: "supplier-authorize-detail"
      }, {
        path: "authorize/document",
        component: _36adeddd,
        name: "supplier-authorize-document"
      }, {
        path: "authorize/intro",
        component: _13bec39a,
        name: "supplier-authorize-intro"
      }, {
        path: "authorize/review",
        component: _aa76dfcc,
        name: "supplier-authorize-review"
      }]
    }, {
      path: "/system",
      component: _53e35306,
      name: "system"
    }, {
      path: "/vehicle",
      component: _d980b436,
      children: [{
        path: "",
        component: _9c2126b0,
        name: "vehicle"
      }, {
        path: "accident-manage",
        component: _5c550c7c,
        name: "vehicle-accident-manage"
      }, {
        path: "annual-manage",
        component: _26db03a8,
        name: "vehicle-annual-manage"
      }, {
        path: "break-rule",
        component: _885666b2,
        name: "vehicle-break-rule"
      }, {
        path: "guarantee-manage",
        component: _178bac67,
        name: "vehicle-guarantee-manage"
      }, {
        path: "license-tag",
        component: _7f263ae7,
        name: "vehicle-license-tag"
      }, {
        path: "maintain-manage",
        component: _7fa49080,
        name: "vehicle-maintain-manage"
      }, {
        path: "out-in-warehouse",
        component: _3bbc2457,
        name: "vehicle-out-in-warehouse"
      }, {
        path: "repair-manage",
        component: _2875a23e,
        name: "vehicle-repair-manage"
      }, {
        path: "traffic-declare",
        component: _9ce78b1a,
        name: "vehicle-traffic-declare"
      }, {
        path: "validate-vehicle",
        component: _0ac227d2,
        name: "vehicle-validate-vehicle"
      }, {
        path: "vehicle-dispose",
        component: _73859c57,
        name: "vehicle-vehicle-dispose"
      }, {
        path: "vehicle-groups",
        component: _137eee72,
        name: "vehicle-vehicle-groups"
      }, {
        path: "vehicle-manage",
        component: _770d5a40,
        children: [{
          path: "",
          component: _777d30c3,
          name: "vehicle-vehicle-manage"
        }, {
          path: "common-use-model",
          component: _06019ea3,
          name: "vehicle-vehicle-manage-common-use-model"
        }, {
          path: "edit",
          component: _9b045c28,
          name: "vehicle-vehicle-manage-edit"
        }, {
          path: "plate-manage",
          component: _44cce344,
          name: "vehicle-vehicle-manage-plate-manage"
        }, {
          path: "vehicle-list",
          component: _156fd57e,
          name: "vehicle-vehicle-manage-vehicle-list"
        }, {
          path: "vehicle-list/edit",
          component: _2ae40c2e,
          name: "vehicle-vehicle-manage-vehicle-list-edit"
        }, {
          path: "plate-manage/detail/:id",
          component: _4fa21820,
          name: "vehicle-vehicle-manage-plate-manage-detail-id"
        }, {
          path: "vehicle-list/detail/:id",
          component: _0173671d,
          name: "vehicle-vehicle-manage-vehicle-list-detail-id"
        }, {
          path: "detail/:id",
          component: _ede5a64a,
          name: "vehicle-vehicle-manage-detail-id"
        }]
      }, {
        path: "accident-manage/add",
        component: _07295b8e,
        name: "vehicle-accident-manage-add"
      }, {
        path: "accident-manage/detail",
        component: _ae78e84c,
        name: "vehicle-accident-manage-detail"
      }, {
        path: "annual-manage/add",
        component: _72f6753e,
        name: "vehicle-annual-manage-add"
      }, {
        path: "annual-manage/annual-record",
        component: _1acdb09c,
        name: "vehicle-annual-manage-annual-record"
      }, {
        path: "annual-manage/detail",
        component: _ccf857ac,
        name: "vehicle-annual-manage-detail"
      }, {
        path: "break-rule/break-query",
        component: _5b3957d2,
        name: "vehicle-break-rule-break-query"
      }, {
        path: "break-rule/home",
        component: _494a75c6,
        name: "vehicle-break-rule-home"
      }, {
        path: "break-rule/manual-search",
        component: _f7248d32,
        name: "vehicle-break-rule-manual-search"
      }, {
        path: "break-rule/record",
        component: _0061a94f,
        name: "vehicle-break-rule-record"
      }, {
        path: "break-rule/search-record",
        component: _7eeff9a4,
        name: "vehicle-break-rule-search-record"
      }, {
        path: "guarantee-manage/add",
        component: _04e80df9,
        name: "vehicle-guarantee-manage-add"
      }, {
        path: "guarantee-manage/detail",
        component: _3b603d8f,
        name: "vehicle-guarantee-manage-detail"
      }, {
        path: "guarantee-manage/guarantee-files",
        component: _379ad78a,
        name: "vehicle-guarantee-manage-guarantee-files"
      }, {
        path: "guarantee-manage/guarantee-record",
        component: _dad7cb18,
        name: "vehicle-guarantee-manage-guarantee-record"
      }, {
        path: "maintain-manage/add",
        component: _2f4f3d92,
        name: "vehicle-maintain-manage-add"
      }, {
        path: "maintain-manage/detail",
        component: _8d878954,
        name: "vehicle-maintain-manage-detail"
      }, {
        path: "maintain-manage/maintain-record",
        component: _71ae1c44,
        name: "vehicle-maintain-manage-maintain-record"
      }, {
        path: "maintain-manage/record-detail",
        component: _e3617884,
        name: "vehicle-maintain-manage-record-detail"
      }, {
        path: "repair-manage/add",
        component: _01a54050,
        name: "vehicle-repair-manage-add"
      }, {
        path: "repair-manage/detail",
        component: _7f355050,
        name: "vehicle-repair-manage-detail"
      }, {
        path: "traffic-declare/detail",
        component: _26d20a03,
        name: "vehicle-traffic-declare-detail"
      }, {
        path: "vehicle-dispose/add",
        component: _1ceef42e,
        name: "vehicle-vehicle-dispose-add"
      }, {
        path: "vehicle-dispose/detail",
        component: _7d7500c2,
        name: "vehicle-vehicle-dispose-detail"
      }, {
        path: "break-rule/home/break-explain",
        component: _3cebf112,
        name: "vehicle-break-rule-home-break-explain"
      }, {
        path: "break-rule/home/support-city",
        component: _f4f7769e,
        name: "vehicle-break-rule-home-support-city"
      }, {
        path: "break-rule/search-record/detail/:id",
        component: _668c2550,
        name: "vehicle-break-rule-search-record-detail-id"
      }, {
        path: "license-tag/detail/:id",
        component: _30e8d937,
        name: "vehicle-license-tag-detail-id"
      }, {
        path: "out-in-warehouse/contract/:id?",
        component: _63c43cc8,
        name: "vehicle-out-in-warehouse-contract-id"
      }, {
        path: "out-in-warehouse/detail/:id?",
        component: _61bb55c7,
        name: "vehicle-out-in-warehouse-detail-id"
      }, {
        path: "out-in-warehouse/form/:id?",
        component: _50e617fa,
        name: "vehicle-out-in-warehouse-form-id"
      }, {
        path: "out-in-warehouse/inspection/:id?",
        component: _3607b32c,
        name: "vehicle-out-in-warehouse-inspection-id"
      }, {
        path: "validate-vehicle/detail/:id",
        component: _123971a8,
        name: "vehicle-validate-vehicle-detail-id"
      }, {
        path: "vehicle-groups/save/:id",
        component: _14493fb8,
        name: "vehicle-vehicle-groups-save-id"
      }]
    }, {
      path: "/home/approval",
      component: _3c4b816f,
      name: "home-approval",
      children: [{
        path: "approved",
        component: _5ebb0a87,
        name: "home-approval-index-approved"
      }, {
        path: "launch",
        component: _4801b783,
        name: "home-approval-index-launch"
      }, {
        path: "monitoring",
        component: _6b58c998,
        name: "home-approval-index-monitoring"
      }, {
        path: "pending",
        component: _0ae7fcf7,
        name: "home-approval-index-pending"
      }]
    }, {
      path: "/home/my",
      component: _edc7ab50,
      name: "home-my"
    }, {
      path: "/home/my-task",
      component: _b38cbaa0,
      name: "home-my-task"
    }, {
      path: "/home/news",
      component: _d16c7b02,
      name: "home-news"
    }, {
      path: "/home/notice",
      component: _5e487c04,
      name: "home-notice"
    }, {
      path: "/home/operationLog",
      component: _b70b0aee,
      name: "home-operationLog"
    }, {
      path: "/home/portal",
      component: _bfa0f6d0,
      name: "home-portal"
    }, {
      path: "/home/task-manage",
      component: _0b37ec17,
      name: "home-task-manage"
    }, {
      path: "/reports/data-center",
      component: _ca0766f8,
      name: "reports-data-center"
    }, {
      path: "/reports/risk-report",
      component: _48f81e1e,
      name: "reports-risk-report"
    }, {
      path: "/home/notice/detail",
      component: _1cf5d95c,
      name: "home-notice-detail"
    }, {
      path: "/home/approval/detail/:detail?",
      component: _55ccc9e5,
      name: "home-approval-detail-detail"
    }, {
      path: "/home/news/detail/:id?",
      component: _1842509f,
      name: "home-news-detail-id"
    }, {
      path: "/",
      component: _4cefde6a,
      name: "index"
    }],

    fallback: false
  })
}
