import moment from 'moment';
export var toThousandFilter = function (num) {
    return (+num || 0).toString().replace(/^-?\d+/g, function (m) { return m.replace(/(?=(?!\b)(\d{3})+$)/g, ','); });
};
export var splitTime = function (val) {
    if (!val || val === '0001-01-01 00:00:00' || val === '0001-01-01') {
        return '--';
    }
    else if (typeof val === 'string' && val.length > 10) {
        return moment(val).format('YYYY-MM-DD');
    }
    else {
        return val;
    }
};
export var validTime = function (val) {
    if (!val || val === '0001-01-01 00:00:00' || val === '0001-01-01') {
        return '--';
    }
    else {
        return val;
    }
};
export var sliceStr = function (value) {
    if (value && value.length > 8) {
        return value.slice(0, 8) + "...";
    }
    else {
        return value;
    }
};
