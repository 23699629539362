var state = function () { return ({}); };
var mutations = {};
var actions = {
    getCaptainList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Captains/GetCaptainList',
            data: aData
        });
    },
    addCaptain: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Captains/AddCaptain',
            data: aData
        });
    },
    updateCaptain: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Captains/update',
            data: aData
        });
    },
    deleteCaptains: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Captains/DeleteCaptains',
            data: aData
        });
    },
    addCaptainCustomer: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Captains/AddCaptainCustomer',
            data: aData
        });
    },
    deleteCaptainCustomers: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Captains/DeleteCaptainCustomers',
            data: aData
        });
    },
    getCaptainCustomerList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Captains/GetCaptainCustomerList',
            data: aData
        });
    },
    getCaptainsDrivers: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Captains/Drivers',
            data: aData
        });
    },
    captainsUsers: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Captains/Users'
        });
    },
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
