var state = function () { return ({
    listParams: {
        OID: null,
        KeyValue: ''
    },
}); };
var getters = {
    getterParams: function (state) {
        Object.assign(state.listParams, { OID: null });
        return state.listParams;
    }
};
var mutations = {
    UPDATEPARAMS: function (state, data) {
        Object.assign(state.listParams, data);
    }
};
var actions = {
    getList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Activity/GetActivities",
            data: Data
        });
    },
    add: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Activity/AddActivity",
            data: Data
        });
    },
    detail: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Activity/GetActivityDetail",
            data: Data
        });
    },
    update: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Activity/UpdateActivity",
            data: Data
        });
    },
    ChangeStatus: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Activity/ChangeStatus",
            data: Data
        });
    },
    getCouponSendStatus: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Activity/getCouponSendStatus"
        });
    },
    Drivers: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Captains/Drivers",
            data: Data
        });
    },
    getModelDetail: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/VehicleModel/" + Data
        });
    },
    AddActivityPreview: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Activity/AddActivityPreview",
            data: Data
        });
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
