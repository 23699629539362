var state = function () { return ({}); };
var mutations = {};
var actions = {
    importUserDriver: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/UserDriver/Import',
            data: aData
        });
    },
    insuranceImageImport: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        var formData = new FormData();
        formData.append('file', Data.file);
        return this.$apiAjax({
            apiName: 'traffic/Insurances/InsuranceImageImport',
            data: Data,
            requestMethod: 'form'
        });
    }
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
