import addFinance from "~/store/modules/common-modules/add-finance";
var state = function () { return ({}); };
var mutations = {};
var actions = {};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {
        addFinance: addFinance
    }
};
