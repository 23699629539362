var state = function () { return ({
    listParams: {
        OID: null,
        KeyValue: null,
        CheckType: null,
        StartCheckTime: '',
        EndCheckTime: ''
    }
}); };
var mutations = {};
var actions = {
    getList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOuts/GetStockInOutCheckList",
            data: Data
        });
    },
    GetStockInOutCheckByVeh: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOuts/GetStockInOutCheckByVeh",
            data: Data
        });
    },
    GetStockInOutCheckById: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOuts/GetStockInOutCheckById",
            data: Data
        });
    },
    GetCheckTypeList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOuts/GetCheckTypeList",
            data: Data
        });
    },
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
