var state = function () { return ({
    listParams: {
        OID: null,
        StartTime: null,
        EndTime: null,
        QStartTime: null,
        QEndTime: null,
        TagStatus: null,
        QueryType: null,
        Deduction: null,
        DeductionSum: null,
        Penalty: null,
        PenaltySum: null,
        KeyValue: null,
        Page: 1,
        PerPage: 8
    },
    queryListParams: {
        OID: null,
        Status: null,
        StartTime: null,
        EndTime: null,
        KeyValue: null,
        Page: 1,
        PerPage: 14
    },
}); };
var getters = {
    getterParams: function (state) {
        Object.assign(state.listParams, { OID: null });
        return state.listParams;
    }
};
var mutations = {
    UPDATEPARAMS: function (state, data) {
        Object.assign(state.listParams, data);
    }
};
var actions = {
    getBreakRuleList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        var requestUrl = "";
        if (Data.type === 1) {
            requestUrl = "violation/ViolationQuery/GetAll";
        }
        else if (Data.type === 2) {
            requestUrl = "violation/ViolationQuery/GetAllByVehicle";
        }
        else {
            requestUrl = "violation/ViolationQuery/GetAllByPerson";
        }
        return this.$apiAjax({
            apiName: requestUrl,
            data: Data.params
        });
    },
    manualQuery: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/TrafficViolation/ManualQuery",
            data: Data
        });
    },
    getStatisticsData: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "violation/ViolationQuery/GetStatistics",
            data: Data
        });
    },
    getBreakRuleQueryList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "violation/ViolationQuery/QueryOrders",
            data: Data
        });
    },
    getQuerySpendAllCount: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "violation/ViolationQuery/QuerySpendAllCount",
            data: Data
        });
    },
    getOrder: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "violation/ViolationQuery/GetOrder",
            data: Data
        });
    },
    getOrderDetails: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "violation/ViolationQuery/GetOrderDetails",
            data: Data
        });
    },
    getOrderDetailsJson: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "violation/ViolationQuery/GetViolationsFromOrderDetailsJson",
            data: Data
        });
    },
    addBreakRule: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "violation/ViolationQuery/create",
            data: Data
        });
    },
    editBreakRule: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "violation/ViolationQuery/update",
            data: Data
        });
    },
    delBreakRule: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "violation/ViolationQuery/delete",
            data: Data
        });
    },
    getBreakRuleDetail: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "violation/ViolationQuery",
            data: Data
        });
    },
    getAllByPersonItem: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/TrafficViolation/GetAllByPersonItem",
            data: Data
        });
    },
    GetDriverViolations: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "violation/ViolationQuery/GetDriverViolations",
            data: Data
        });
    },
    getByVehicleItem: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "violation/ViolationQuery/GetAllByVehicleItem",
            data: Data
        });
    },
    getByPersonItem: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "violation/ViolationQuery/GetAllByPersonItem",
            data: Data
        });
    },
    queryVehicles: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "violation/ViolationQuery/QueryVehicles",
            data: Data
        });
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
