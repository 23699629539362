import creditCommon from '~/store/modules/credit/credit-common';
var state = function () { return ({}); };
var mutations = {};
var actions = {};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {
        creditCommon: creditCommon,
    }
};
