var state = function () { return ({}); };
var mutations = {};
var actions = {
    GetAllByEntryIdAsync: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/FollowUpRecord/GetAllByEntryIdAsync",
            data: Data
        });
    },
    FilesByID: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Attachment/all/" + Data
        });
    },
    searchDriverList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "user/UserDriver/Search",
            data: Data
        });
    },
    getVehiclePlateList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Vehicle/plate",
            data: Data
        });
    },
    getCurrentOrgPlateList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Vehicle/GetCurrentOrgPlateList",
            data: Data
        });
    },
    GetAllUsers: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "user/SysUser/GetAllUsers",
            data: Data
        });
    },
    GetAllSupplierByType: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Supplier/GetSupplierScreenList",
            data: Data
        });
    },
    GetSupplierTypeList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Supplier/GetSupplierTypeList"
        });
    },
    GetAllByType: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        if (!state.AccidentByType.length) {
            return this.$apiAjax({
                apiName: "traffic/Tag/GetAllByType/" + Data
            });
        }
    },
    getAccountStatistics: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "violation/ViolationQuery/AccountStatistics",
            data: Data
        });
    },
    GetRecords: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/BusinessLog/GetRecords",
            data: Data
        });
    },
    getFeeListByOID: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Bills/GetFeeListByOID",
            data: data
        });
    },
    billPay: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Bills/Pay",
            data: data
        });
    },
    BatchBills: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Bills/BatchBills",
            data: data
        });
    },
    BatchCollection: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Bills/BatchCollection",
            data: data
        });
    },
    BatchPay: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Bills/BatchPay",
            data: data
        });
    },
    GetDriverPayRecord: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/PayRecord/GetDriverPayRecord",
            data: Data
        });
    },
    getCustomers: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            method: "GET",
            apiName: "saas/Bills/GetCustomers",
            data: data
        });
    },
    getCustomerContracts: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Bills/GetCustomerContracts",
            data: data
        });
    },
    getBussinessType: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Goods/GetBussinessType",
            data: Data
        });
    },
    CreateQRCode: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/QRCode/CreateQRCode",
            data: Data
        });
    },
    BusinessGrade: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/BusinessCustomers/BusinessGrade",
            data: Data
        });
    },
    BusinessStatus: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/BusinessCustomers/BusinessStatus",
            data: Data
        });
    },
    FollowedType: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/BusinessCustomers/FollowedType",
            data: Data
        });
    },
    getCurrentModel: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/VehCommonlyUsed/allmodels"
        });
    },
    GetSchemeListByBusinessTypeAndVehicleModel: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Scheme/GetSchemeListByBusinessTypeAndVehicleModel",
            data: Data
        });
    },
    GetBusinessFlowMap: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/BusinessFlow/GetBusinessFlowMap",
            data: Data
        });
    },
    GetDisposaleVehicleList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Vehicle/GetDisposaleVehicleList",
            data: Data
        });
    },
    RegistrationPlatformList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "user/UserDriver/RegistrationPlatformLis",
            data: Data
        });
    },
    GetInsurancesTypeList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Insurances/GetInsurancesTypeList",
            data: Data
        });
    },
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
