var state = function () { return ({}); };
var mutations = {};
var actions = {
    tenantServices: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Tenant/Services'
        });
    },
    SubmitPaymentOrgInfo: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/PaymentService/SubmitPaymentOrgInfo',
            data: aData
        });
    },
    PaymentOrgInfoForEdit: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/PaymentService/PaymentOrgInfoForEdit'
        });
    },
    EnterpriseInfo: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/PaymentService/EnterpriseInfo'
        });
    },
    PaymentServiceStatus: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/PaymentService/Status'
        });
    },
    GetCode: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/User/GetCode',
            data: aData
        });
    },
    EnterpriseIntroForBind: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/PaymentService/EnterpriseIntroForBind',
            data: aData
        });
    },
    BindPaymentToken: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/PaymentService/BindPaymentToken',
            data: aData
        });
    },
    AccountStatistics: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'violation/ViolationQuery/AccountStatistics',
        });
    },
    SysUserSearch: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/SysUser/Search',
            data: aData
        });
    },
    EcontractAuthenticate: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'econtract/Company/Authenticate',
            data: aData
        });
    },
    FileStatistics: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'econtract/Company/FileStatistics',
            data: aData
        });
    },
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
