var state = function () { return ({}); };
var mutations = {};
var actions = {
    addDriver: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/UserDriver/AddUserDriver',
            data: aData
        });
    },
    delUserDriver: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/UserDriver/DeleteUserDriverById',
            data: aData
        });
    },
    getDriverList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/UserDriver/GetDriverList',
            data: aData
        });
    },
    searchDriverList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/UserDriver/Search',
            data: aData
        });
    },
    getSingleUserDriver: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/UserDriver/GetSingleUserDriverById',
            data: aData
        });
    },
    updateUserDriverInfo: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/UserDriver/UpdateUserDriver',
            data: aData
        });
    },
    getCompanyList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/EnterpriseCustomer/GetEnterpriseCustomerList',
            data: aData
        });
    },
    addNewCompanyInfo: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/EnterpriseCustomer/AddEnterpriseCustomer',
            data: aData
        });
    },
    delNewCompanyInfo: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/EnterpriseCustomer/DeleteEnterpriseCustomer',
            data: aData
        });
    },
    getCompanyDetailInfo: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/EnterpriseCustomer/GetSingleEnterpriseCustomerById',
            data: aData
        });
    },
    updateUserCompInfo: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/EnterpriseCustomer/UpdateEnterpriseCustomer',
            data: aData
        });
    },
    transferUserDriver: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/UserDriver/TransferUserDriver',
            data: aData
        });
    },
    getUserDriversCountByOID: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/UserDriver/GetUserDriversCountByOID',
            data: aData
        });
    },
    getEnterpriseCustomerCountByOID: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/EnterpriseCustomer/GetEnterpriseCustomerCountByOID',
            data: aData
        });
    },
    getBills: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Bills/GetBills',
            data: aData
        });
    },
    addContract: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Niches/AddContract',
            data: aData
        });
    },
    GetCaptainUsersScreen: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Captains/CaptainUsersScreen',
            data: aData
        });
    },
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
