var state = function () { return ({}); };
var mutations = {};
var actions = {
    getUserList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/SysUser/List',
            data: aData
        });
    },
    delUserInfo: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/SysUser/Del',
            data: aData
        });
    },
    resetPassword: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/SysUser/ResetPassword',
            data: aData
        });
    },
    getRoleSimpleList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Role/SimpleList',
            data: aData
        });
    },
    addUserCreate: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/SysUser/Create',
            data: aData
        });
    },
    editUserUpdate: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/SysUser/Update',
            data: aData
        });
    },
    getUsualRoleList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Role/List',
            data: aData
        });
    },
    delUsualRole: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Role/Del',
            data: aData
        });
    },
    getRolePermission: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Role/GetRolePermission',
            data: aData
        });
    },
    addEditRoleSave: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Role/Save',
            data: aData
        });
    },
    getRoleInfos: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Role/GetByID',
            data: aData
        });
    },
    getListBySignContract: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/coupon/getDriverListBySignContract',
            data: aData
        });
    },
    queryUserList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/SysUser/UserList',
            data: aData
        });
    },
    deleteUser: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/SysUser/DeleteUser',
            data: aData
        });
    },
    deleteInvite: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/SysUser/DeleteInvite',
            data: aData
        });
    },
    updateUserV2: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/SysUser/UpdateUserV2',
            data: aData
        });
    },
    changeStatus: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/SysUser/ChangeStatus',
            data: aData
        });
    },
    inviteAgain: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/SysUser/InviteAgain',
            data: aData
        });
    },
    inviteUser: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/SysUser/InviteUser',
            data: aData
        });
    },
    getInviteUrl: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/SysUser/GetInviteUrl',
            data: aData
        });
    },
    getInvitingUrl: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/SysUser/GetInvitingUrl',
            data: aData
        });
    },
    getWaitCheckUserCount: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/SysUser/GetWaitCheckUserCount',
            data: aData
        });
    },
    getWaitCheckUserList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/SysUser/GetWaitCheckUserList',
            data: aData
        });
    },
    checkWaitUser: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/SysUser/CheckWaitUser',
            data: aData
        });
    },
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
