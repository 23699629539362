var state = function () { return ({
    listParams: {
        "OID": null,
        "BusinessType": '',
        "BackType": '',
        "SettleStatus": -1,
        "KeyValue": ''
    }
}); };
var mutations = {
    UPDATEPARAMS: function (state, data) {
        Object.assign(state.listParams, data);
    }
};
var getters = {
    getterParams: function (state) {
        Object.assign(state.listParams, { OID: null });
        return state.listParams;
    }
};
var actions = {
    getListSettlement: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/settlement/getList',
            data: aData
        });
    },
    getSettleDetail: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/settlement/getSettleDetail',
            data: aData
        });
    },
    saveSettle: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/settlement/saveSettle',
            data: aData
        });
    },
    getSettleClearDetail: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/settlement/getSettleClearDetail',
            data: aData
        });
    },
    saveSettleClear: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/settlement/saveSettleClear',
            data: aData
        });
    },
    getListSchemeFeeConfig: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/SchemeFeeConfig/GetList',
            data: aData
        });
    },
    getViolations: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/settlement/getViolations',
            data: aData
        });
    },
    SettlePreview: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/settlement/SettlePreview",
            data: aData
        });
    },
    SettleClearPreview: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/settlement/SettleClearPreview",
            data: aData
        });
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
