import authorize from '~/store/modules/supplier/authorize';
var state = function () { return ({}); };
var mutations = {};
var actions = {};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {
        authorize: authorize,
    }
};
