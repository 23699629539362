var state = function () { return ({}); };
var mutations = {};
var actions = {
    getStockInOutCheckConfigList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOutCheckConfig/GetStockInOutCheckConfigList",
            data: Data
        });
    },
    createStockInOutCheckItem: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOutCheckConfig/CreateStockInOutCheckItem",
            data: Data
        });
    },
    deleteStockInOutCheckItem: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOutCheckConfig/DeleteStockInOutCheckItem",
            data: Data
        });
    },
    updateStockInOutCheckItem: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOutCheckConfig/UpdateStockInOutCheckItem",
            data: Data
        });
    },
    getStockInOutCheckItemList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOutCheckConfig/GetStockInOutCheckItemList",
            data: Data
        });
    },
    addStockInOutCheckConfig: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOutCheckConfig/AddStockInOutCheckConfig",
            data: Data
        });
    },
    changeStockInOutCheckConfigStatus: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOutCheckConfig/ChangeStockInOutCheckConfigStatus",
            data: Data
        });
    },
    getStockInOutCheckConfigDetailsByID: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOutCheckConfig/GetStockInOutCheckConfigDetailsByID",
            data: Data
        });
    },
    deleteStockInOutCheckConfig: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOutCheckConfig/DeleteStockInOutCheckConfig",
            data: Data
        });
    },
    updateStockInOutCheckConfig: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOutCheckConfig/UpdateStockInOutCheckConfig",
            data: Data
        });
    }
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
