import merchandise from '~/store/modules/business/merchandise';
import saleAction from '~/store/modules/business/sale-action';
import businessManage from '~/store/modules/business/business-manage';
import subscribeOrder from '~/store/modules/business/subscribe-order';
var state = function () { return ({}); };
var mutations = {};
var actions = {};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {
        merchandise: merchandise,
        saleAction: saleAction,
        businessManage: businessManage,
        subscribeOrder: subscribeOrder
    }
};
