var state = function () { return ({
    listParams: {
        OID: null,
        ActivityID: null,
        Status: '-1',
        KeyValue: ''
    },
}); };
var getters = {
    getterParams: function (state) {
        Object.assign(state.listParams, { OID: null });
        return state.listParams;
    }
};
var mutations = {
    UPDATEPARAMS: function (state, data) {
        Object.assign(state.listParams, data);
    }
};
var actions = {
    getList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Activity/GetActivityCouponsByPage',
            data: aData
        });
    },
    GetSimpleActivities: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Activity/GetSimpleActivities',
            data: aData
        });
    },
    detail: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Activity/GetActivityCouponDetail',
            data: aData
        });
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
