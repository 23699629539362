import Crumb from '~/utils/crumb';
import { MessageBox } from 'element-ui';
export default (function (_a, inject) {
    var app = _a.app, env = _a.env, store = _a.store;
    inject('envConfig', function () {
        return env;
    });
    app.router.beforeEach(function (to, from, next) {
        var INFO = app.$Cache().getInfo();
        var TOKEN = app.$Cache().getToken();
        if (TOKEN) {
            var pathSplit = to.path.split('/');
            for (var i = 0, l = store.state.menus.length; i < l; i++) {
                if (pathSplit[0] === '' && pathSplit[1] === '') {
                    store.commit('ACTIVEMODULE', '0');
                    break;
                }
                if (store.state.menus[i].Code === pathSplit[1]) {
                    store.commit('ACTIVEMODULE', i.toString());
                    break;
                }
            }
            if (INFO.CompanyID !== '-1' || to.name === 'home-my' || to.name === 'organization') {
                new Crumb(store.state.sideMenu, to.path, store._mutations);
                next();
            }
            else {
                next({ path: '/organization', replace: true });
            }
        }
        else {
            MessageBox.confirm('用户未通过身份认证，即将退出系统，请重新登录！', '提示', {
                confirmButtonText: '重新登录',
                showCancelButton: false,
                type: 'warning',
                center: true
            }).then(function () {
                location.href = env.urlConfig.login;
            }).catch(function (action) {
                location.href = env.urlConfig.login;
            });
        }
    });
});
