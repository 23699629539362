var state = function () { return ({
    listParams: {
        OID: null,
        OwnerType: null,
        UseProperties: null,
        UseType: null,
        Status: null,
        KeyValue: null,
        Page: 1,
        PerPage: 10
    }
}); };
var mutations = {
    setListParams: function (state, Data) {
        state.listParams = Data;
    }
};
var actions = {
    addPlate: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Plate/create",
            data: Data
        });
    },
    getPlateList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Plate/page",
            data: Data
        });
    },
    plateDetail: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            method: "GET",
            apiName: "saas/Plate/" + Data
        });
    },
    updatePlate: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Plate/update",
            data: Data
        });
    },
    deletePlate: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Plate/delete",
            data: Data
        });
    },
    bindVehicleHistory: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            method: "GET",
            apiName: "saas/Plate/VehicleHistory/" + Data
        });
    }
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
