var state = function () { return ({}); };
var mutations = {};
var actions = {
    getNicheList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Niches/GetNicheList',
            data: aData
        });
    },
    updateNichesScheme: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Niches/UpdateNichesScheme',
            data: aData
        });
    },
    getSingleNiche: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Niches/GetSingleNiche',
            data: aData
        });
    },
    cancelNiche: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Niches/CancelNiche',
            data: aData
        });
    },
    getNicheOrderById: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Niches/GetNicheOrderById',
            data: aData
        });
    },
    getFolloweRecordsList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/FolloweRecords/GetList',
            data: aData
        });
    },
    addFolloweRecords: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/FolloweRecords/AddFolloweRecords',
            data: aData
        });
    },
    getSchemeListByGoodsId: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Scheme/GetSchemeListByGoodsId',
            data: aData
        });
    },
    getOrdersList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Orders/GetOrdersList',
            data: aData
        });
    },
    addOrderBooking: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Orders/AddOrder',
            data: aData
        });
    },
    addOrderNiches: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Niches/AddOrder',
            data: aData
        });
    },
    updateOrderBooking: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Orders/UpdateOrder',
            data: aData
        });
    },
    cancelOrderBooking: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Orders/CancelOrder',
            data: aData
        });
    },
    getOrderDetailsById: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Orders/GetOrderDetailsById',
            data: aData
        });
    },
    addRemarkRecords: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/RemarkRecords/Add',
            data: aData
        });
    },
    getRemarkRecordList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/RemarkRecords/GetRemarkRecordList',
            data: aData
        });
    },
    getCustomerInfo: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Orders/GetCustomerInfo',
            data: aData
        });
    },
    updateCustomerInfo: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Orders/UpdateCustomerInfo',
            data: aData
        });
    },
    updateDeposit: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Orders/UpdateDeposit',
            data: aData
        });
    },
    cancelMyOrder: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Orders/CancelOrder',
            data: aData
        });
    },
    getUnallocatedOrder: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Orders/GetUnallocatedOrder',
            data: aData
        });
    },
    distributionOrder: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Orders/Distribution',
            data: aData
        });
    },
    getOrdersCountByOID: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Orders/GetOrdersCountByOID',
            data: aData
        });
    },
    getNichesCountByOID: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Niches/GetNichesCountByOID',
            data: aData
        });
    },
    updateMyOrderScheme: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Orders/UpdateOrderScheme',
            data: aData
        });
    },
    getBusinessFlow: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/BusinessFlow/GetBusinessFlow',
            data: aData
        });
    }
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
