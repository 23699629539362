import Cookies from 'js-cookie';
var state = {
    stateParams: {
        OID: ["-1"],
        BillType: "1",
        FeeID: [],
        BusinessType: null,
        Dates: null,
        StartDate: '',
        EndDate: '',
        KeyValue: ''
    }
};
var getters = {
    getterParams: function (state) {
        var info = Cookies.get('saas-info');
        info = info ? JSON.parse(info) : {};
        Object.assign(state.stateParams, { OID: [info.OID] });
        return state.stateParams;
    },
};
var mutations = {
    UPDATEPARAMS: function (state, data) {
        Object.assign(state.stateParams, data);
    }
};
var actions = {
    getBillList: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Bills/GetCustomerBillList",
            data: data
        });
    },
    getCustomerBillDetailByBillId: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Bills/GetCustomerBillDetailByBillId",
            data: data
        });
    },
    getContractBillInfos: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Bills/GetContractBillInfos",
            data: data
        });
    },
    fastBillReceive: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Bills/FastBillReceive",
            data: data
        });
    },
    billPay: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Bills/Pay",
            data: data
        });
    },
    revoke: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Bills/PaymentCancellation",
            data: data
        });
    }
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
