var state = function () { return ({}); };
var mutations = {};
var actions = {
    getOrganization: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Organization/GetList',
            data: aData
        });
    },
    getListWithDepartment: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Organization/GetListWithDepartment'
        });
    },
    createOrganization: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Organization/Create',
            data: aData
        });
    },
    deleteOrganization: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Organization/Del',
            data: aData
        });
    },
    updateNameOrganization: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Organization/UpdateName',
            data: aData
        });
    },
    createOrganizationDepartment: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Organization/CreateOrganizationDepartment',
            data: aData
        });
    },
    delOrganizationDepartment: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Organization/DelOrganizationDepartment',
            data: aData
        });
    },
    updateOrganizationDepartment: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Organization/UpdateDepartment',
            data: aData
        });
    },
    getOrganizationInfo: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Organization/Get',
            data: aData
        });
    },
    updateOrganizationInfo: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Organization/Update',
            data: aData
        });
    },
    getDepartmentSimpleList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Organization/GetDepartmentSimpleList',
            data: aData
        });
    },
    GetSysUserOrganizationDetails: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/SysUser/GetSysUserOrganizationDetails',
            data: aData
        });
    },
    GetOrganizationDetailsService: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/SysUser/GetOrganizationDetailsService',
            data: aData
        });
    },
    getManager: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/SysUserInvite/GetManager',
            data: aData
        });
    },
    changeManager: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/SysUserInvite/ChangeManager',
            data: aData
        });
    },
    getAllUsers: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/SysUser/GetAllUsers',
            data: aData
        });
    }
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
