var state = function () { return ({
    listParams: {
        OID: null,
        MaintenanceState: '2',
        BillingState: null,
        StartDate: null,
        EndDate: null,
        MID: null,
        KeyValue: ''
    },
    recordParams: {
        OID: null,
        BillingState: null,
        StartDate: null,
        EndDate: null,
        KeyValue: ''
    },
    StatusType: [
        {
            name: '将到期',
            value: 2
        },
        {
            name: '脱保',
            value: 3
        },
        {
            name: '正常',
            value: 1
        },
        {
            name: '未设置周期',
            value: 4
        },
    ],
    annualResult: [
        {
            name: '合格',
            value: 0
        },
        {
            name: '不合格',
            value: 1
        }
    ],
    transactorType: [
        {
            name: '公司',
            value: 0
        },
        {
            name: '客户',
            value: 1
        }
    ]
}); };
var getters = {
    getterParams: function (state) {
        Object.assign(state.listParams, { OID: null });
        return state.listParams;
    },
    getterRecordParams: function (state) {
        Object.assign(state.recordParams, { OID: null });
        return state.recordParams;
    },
};
var mutations = {
    UPDATEPARAMS: function (state, data) {
        Object.assign(state.listParams, data);
    },
    UPDATERECORDPARAMS: function (state, data) {
        Object.assign(state.recordParams, data);
    },
};
var actions = {
    getList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Maintenance/page",
            data: Data
        });
    },
    record: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Maintenance/DetailPage",
            data: Data
        });
    },
    add: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Maintenance/create",
            data: Data
        });
    },
    update: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Maintenance/update",
            data: Data
        });
    },
    detail: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Maintenance/" + Data
        });
    },
    valueDetail: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Maintenance/Vehicle/" + Data
        });
    },
    VehicleDetailPage: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Maintenance/VehicleDetailPage",
            data: Data
        });
    },
    delete: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Maintenance/delete",
            data: Data
        });
    },
    SetPeriod: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'traffic/Maintenance/SetPeriod',
            data: aData
        });
    },
    plates: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'traffic/Maintenance/plates',
            data: aData
        });
    },
    models: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'traffic/Maintenance/models',
            data: aData
        });
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
