import basesets from '~/store/modules/system/basesets';
import usermanage from '~/store/modules/system/usermanage';
import companymanage from '~/store/modules/system/company-manage';
import contractfeeconfig from "~/store/modules/system/contract-fee-config";
import verifyconfig from "~/store/modules/system/verify-config";
import rentconfig from "~/store/modules/system/rent-config";
import checkvehicleconfig from "~/store/modules/system/check-vehicle-config";
import contractTpl from "~/store/modules/system/contract-tpl";
var state = function () { return ({}); };
var mutations = {};
var actions = {};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {
        basesets: basesets,
        usermanage: usermanage,
        companymanage: companymanage,
        contractfeeconfig: contractfeeconfig,
        verifyconfig: verifyconfig,
        rentconfig: rentconfig,
        checkvehicleconfig: checkvehicleconfig,
        contractTpl: contractTpl
    }
};
