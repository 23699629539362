var state = function () { return ({}); };
var mutations = {};
var actions = {
    selectTags: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Tag/GetTagList',
            data: aData
        });
    },
    addDTags: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Tag/AddTags',
            data: aData
        });
    },
    editDTags: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Tag/UpdateTags',
            data: aData
        });
    },
    delDTags: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Tag/DeleteTags',
            data: aData
        });
    },
    bindsDTags: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Tag/AddTagsRelation',
            data: aData
        });
    },
    delbindsDTags: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/Tag/DeleteTagsRelation',
            data: aData
        });
    },
    bindVehicleTags: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Vehicle/createtag',
            data: aData
        });
    },
    delVehicleTags: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Vehicle/removetag',
            data: aData
        });
    },
    getSaasTagsList: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Tags/GetList',
            data: aData
        });
    },
    createSaasTags: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Tags/Create',
            data: aData
        });
    },
    delSaasTags: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Tags/Del',
            data: aData
        });
    },
    updateSaasTags: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Tags/Update',
            data: aData
        });
    },
    getCurCompanyVehicleTags: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Tags/CurrentList',
            data: aData
        });
    }
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
