import Cookies from 'js-cookie';
var state = {
    stateParams: {
        OID: null,
        VehicleSource: null,
        TagStatus: null,
        VehicleModel: null,
        KeyValue: null,
        InLease: null,
        StockInOutType: null,
        ManagementStatus: false,
        AccidentStatus: false,
        ViolationStatus: false,
        RepairStatus: false,
        AnnualInspectionStatus: false,
        MaintenanceStatus: false,
        InsurancesStatus: false,
        ComInsurancesStatus: false,
        IsPlate: false,
        AnnualInspectionOverdue: false,
    }
};
var getters = {
    getterParams: function (state) {
        var info = Cookies.get('saas-info');
        info = info ? JSON.parse(info) : {};
        Object.assign(state.stateParams, { OID: null });
        return state.stateParams;
    },
};
var mutations = {
    UPDATEPARAMS: function (state, data) {
        Object.assign(state.stateParams, data);
    }
};
var actions = {
    getVehicleList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Vehicle/page",
            data: Data
        });
    },
    getVehicleManageList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Vehicle/VehicleList",
            data: Data
        });
    },
    getVehiclePlateList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Vehicle/plate",
            data: Data
        });
    },
    getNoUsedPlateList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Plate/NoUseds",
            data: Data
        });
    },
    getVehicleDetail: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Vehicle/" + Data,
            method: "GET"
        });
    },
    addVehicle: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Vehicle/create",
            data: Data
        });
    },
    editVehicle: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Vehicle/update",
            data: Data
        });
    },
    deleteVehicle: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Vehicle/delete",
            data: Data
        });
    },
    getVehicleOwnerType: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Vehicle/VehicleOwnerType",
            data: Data
        });
    },
    getVehilcePlateType: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Vehicle/VehilcePlateType",
            data: Data
        });
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
