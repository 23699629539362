var state = function () { return ({
    listParams: {
        OID: '',
        DeclarationType: '0',
        Status: null,
        KeyValue: ''
    },
    StatusType: [
        {
            name: '申报记录',
            value: 0
        },
        {
            name: '出险',
            value: 1
        },
        {
            name: '保养',
            value: 2
        },
        {
            name: '故障维修',
            value: 3
        },
    ],
    declareType: [
        {
            Display: '处理中',
            Value: 1
        },
        {
            Display: '已处理',
            Value: 2
        }
    ],
}); };
var getters = {
    getterParams: function (state) {
        Object.assign(state.listParams, { OID: null });
        return state.listParams;
    },
};
var mutations = {
    UPDATEPARAMS: function (state, data) {
        Object.assign(state.listParams, data);
    }
};
var actions = {
    getList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/VehicleService/VehicleServiceList",
            data: Data
        });
    },
    handleDeclaration: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/VehicleService/HandleDeclaration",
            data: Data
        });
    },
    detail: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/VehicleService/DeclarationDetail",
            data: Data
        });
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
