import hmacSHA256 from 'crypto-js/hmac-sha256';
import { Message, MessageBox } from 'element-ui';
var getSign = function (params) {
    var filters = [undefined, null, ''];
    var signParams = [];
    for (var key in params) {
        var val = params[key];
        if (filters.indexOf(val) < 0) {
            signParams.push([key, val].join('='));
        }
    }
    return hmacSHA256(signParams.join('&'), 'sfEFjahDugegfwefhuRhfWvfhegAefew').toString();
};
export default (function (_a, inject) {
    var $axios = _a.$axios, app = _a.app, env = _a.env, store = _a.store, redirect = _a.redirect;
    $axios.defaults.timeout = 300000;
    var redirectFlag = false;
    $axios.onRequest(function (config) {
        var TOKEN = app.$Cache().getToken();
        if (TOKEN) {
            config.headers['Token'] = TOKEN;
        }
    });
    $axios.onResponse(function (response) {
        var res = response.data;
        var isMsg = response.config.headers.isMsg;
        var state = res.State;
        if (state !== 1) {
            var msg = '';
            switch (state) {
                case -11:
                    msg = '用户未通过身份认证，即将退出系统，请重新登录！';
                    break;
                case -17:
                    msg = '您的账号在其他处登录，即将退出系统，请重新登录！';
                    break;
                case -19:
                    msg = '您对应的角色权限已修改，请刷新权限或重新登录！';
                    break;
            }
            if ([-11, -17, -19].indexOf(state) >= 0) {
                if (state !== -19) {
                    app.$Cache().removeToken();
                }
                if (!redirectFlag) {
                    redirectFlag = true;
                    MessageBox.confirm(msg, '提示', {
                        confirmButtonText: '重新登录',
                        cancelButtonText: '刷新权限',
                        showCancelButton: state === -19 ? true : false,
                        type: 'warning',
                        center: true
                    }).then(function () {
                        redirectFlag = false;
                        location.href = env.urlConfig.login;
                    }).catch(function (action) {
                        if (action === 'cancel') {
                            redirectFlag = false;
                            store.dispatch('loadLoginAuto').then(function (res) {
                                var result = res.data;
                                if (result.State === 1) {
                                    store.commit('SYSMENUS', result.Data.Menus);
                                    location.reload();
                                }
                            });
                        }
                        else {
                            redirectFlag = false;
                            location.href = env.urlConfig.login;
                        }
                    });
                }
            }
            else {
                if (isMsg) {
                    Message({ message: response.data.Message, type: 'error' });
                }
            }
        }
    });
    $axios.onError(function (error) {
        var code = parseInt(error.response && error.response.status);
        if (code === 400) {
            redirect('/400');
        }
        if (error.code === 500) {
            redirect('/');
        }
        if (!code) {
            Message({ message: error.message, type: 'error' });
        }
    });
    $axios.onResponseError(function (response) {
        console.log('ResponseError:', response);
    });
    inject('apiAjax', function (params) {
        var axiosParams = {
            method: 'post',
            url: '/api/gateway'
        };
        if (params.requestMethod === 'form') {
            this.$axios.setHeader('Content-Type', 'multipart/form-data;');
            Object.assign(axiosParams, { url: params.apiName, data: params.data });
        }
        else {
            var gateWayParams = {
                Content: params.data ? JSON.stringify(params.data) : '',
                ContentType: "application/json",
                Interface: '/api/' + params.apiName,
                Version: "v1.0.0"
            };
            Object.assign(gateWayParams, { Sign: getSign(gateWayParams) });
            Object.assign(axiosParams, { data: Object.seal(gateWayParams) });
        }
        if (params.isMsg === undefined) {
            params.isMsg = true;
        }
        this.$axios.setHeader('isMsg', params.isMsg);
        if (params.config) {
            Object.assign(axiosParams, params.config);
        }
        var TOKEN = app.$Cache().getToken();
        var isState = (!TOKEN || TOKEN.length < 16);
        if (isState) {
            MessageBox.confirm('用户未通过身份认证，即将退出系统，请重新登录！', '提示', {
                confirmButtonText: '重新登录',
                showCancelButton: false,
                type: 'warning',
                center: true
            }).then(function () {
                location.href = env.urlConfig.login;
            }).catch(function (action) {
                location.href = env.urlConfig.login;
            });
            return { data: { State: -1001, Data: null, PageInfo: {}, Message: null } };
        }
        return this.$axios(axiosParams);
    });
});
