var state = function () { return ({}); };
var mutations = {};
var actions = {
    EContractsList: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'econtract/EContracts/Page',
            data: data
        });
    },
    EContractsCreate: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'econtract/EContracts/Create',
            data: data
        });
    },
    EContractsUpdate: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'econtract/EContracts/Update',
            data: data
        });
    },
    GetEContractInfo: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'econtract/EContracts/Get',
            data: data
        });
    },
    GetEContractDetail: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'econtract/EContracts/Detail',
            data: data
        });
    },
    EContractDelete: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'econtract/EContracts/Delete',
            data: data
        });
    },
    EContractCancel: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'econtract/EContracts/Cancel',
            data: data
        });
    },
    EContractSign: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'econtract/EContracts/Sign',
            data: data
        });
    },
    SignReturnCallBack: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'econtract/Callback/SignReturnCallBack',
            data: data
        });
    },
    EContractDownload: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'econtract/EContracts/Download',
            data: data
        });
    },
    UsageRecordPage: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'econtract/EContracts/UsageRecordPage',
            data: data
        });
    },
    BusinessKeyValues: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'econtract/EContracts/BusinessKeyValues',
            data: data
        });
    },
    ContractTemplateKeywordValues: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'econtract/EContracts/ContractTemplateKeywordValues',
            data: data
        });
    },
    SignatureList: function () {
        return this.$apiAjax({
            apiName: 'econtract/SignatureImage/List'
        });
    },
    CreateSignature: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'econtract/SignatureImage/Create',
            data: data
        });
    },
    SetDefault: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'econtract/SignatureImage/SetDefault',
            data: data
        });
    },
    DeleteSignature: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'econtract/SignatureImage/Delete',
            data: data
        });
    },
    DriverValidContractSelectList: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Contract/DriverValidContractSelectList',
            data: data
        });
    },
    GetPlanList: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'econtract/Plan/List',
            data: data
        });
    },
    GetOrderList: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'econtract/Company/ValidOrderPage',
            data: data
        });
    },
    GetOrderDetail: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'econtract/Order/Detail',
            data: data
        });
    },
    PlaceOrder: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'econtract/Order/PlaceOrder',
            data: data
        });
    },
    PayStatus: function (_a, data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'econtract/Order/PayStatus',
            data: data
        });
    },
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
