import bill from "~/store/modules/finace/bill";
import income from "~/store/modules/finace/income";
import account from "~/store/modules/finace/account";
import BillCredit from "~/store/modules/finace/bill-credit";
import BillContract from "~/store/modules/finace/bill-contract";
import BillClient from "~/store/modules/finace/bill-client";
import BillSupplier from "~/store/modules/finace/bill-supplier";
var state = function () { return ({}); };
var mutations = {};
var actions = {};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {
        bill: bill,
        income: income,
        account: account,
        BillCredit: BillCredit,
        BillContract: BillContract,
        BillClient: BillClient,
        BillSupplier: BillSupplier
    }
};
