import vehicle from "~/store/modules/vehicle/vehicle";
import plate from "~/store/modules/vehicle/plate";
import vehiclemodel from "~/store/modules/vehicle/vehicle-model";
import breakrule from "~/store/modules/vehicle/break-rule";
import warehouse from "~/store/modules/vehicle/warehouse";
import annual from "~/store/modules/vehicle/annual";
import accident from "~/store/modules/vehicle/accident";
import maintain from "~/store/modules/vehicle/maintain";
import guarantee from "~/store/modules/vehicle/guarantee";
import repair from "~/store/modules/vehicle/repair";
import declare from "~/store/modules/vehicle/declare";
import groups from "~/store/modules/vehicle/groups";
import breakhome from "~/store/modules/vehicle/break-home";
import breakquery from "~/store/modules/vehicle/break-query";
import vehicleDispose from "~/store/modules/vehicle/vehicle-dispose";
import validateVehicle from '~/store/modules/vehicle/validate-vehicle';
var state = function () { return ({}); };
var mutations = {};
var actions = {};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {
        vehicle: vehicle,
        plate: plate,
        vehiclemodel: vehiclemodel,
        breakrule: breakrule,
        warehouse: warehouse,
        annual: annual,
        accident: accident,
        maintain: maintain,
        guarantee: guarantee,
        repair: repair,
        declare: declare,
        groups: groups,
        breakhome: breakhome,
        breakquery: breakquery,
        vehicleDispose: vehicleDispose,
        validateVehicle: validateVehicle
    }
};
