import Vue from 'vue';
import Vuex from 'vuex';
import actions from '~/store/actions';
import mutations from '~/store/mutations';
import state from '~/store/state';
import getters from '~/store/getters';
import home from '~/store/modules/home';
import rent from '~/store/modules/rent';
import business from '~/store/modules/business';
import common from '~/store/modules/common';
import vehicle from '~/store/modules/vehicle';
import finace from '~/store/modules/finace';
import system from '~/store/modules/system';
import credit from '~/store/modules/credit';
import commonModules from '~/store/modules/common-modules';
import merchant from '~/store/modules/merchant';
import supplier from '~/store/modules/supplier';
import reports from '~/store/modules/reports';
Vue.use(Vuex);
var createStore = function () {
    return new Vuex.Store({
        actions: actions,
        mutations: mutations,
        getters: getters,
        state: state,
        modules: {
            home: home,
            rent: rent,
            vehicle: vehicle,
            business: business,
            finace: finace,
            system: system,
            common: common,
            credit: credit,
            commonModules: commonModules,
            merchant: merchant,
            supplier: supplier,
            reports: reports
        }
    });
};
export default createStore;
