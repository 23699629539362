import { render, staticRenderFns } from "./Main.vue?vue&type=template&id=7557ec56&scoped=true&"
import script from "./Main.vue?vue&type=script&lang=ts&"
export * from "./Main.vue?vue&type=script&lang=ts&"
import style0 from "./Main.vue?vue&type=style&index=0&id=7557ec56&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7557ec56",
  null
  
)

component.options.__file = "Main.vue"
export default component.exports