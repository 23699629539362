var state = function () { return ({
    accountStatistics: {
        AllCount: null,
        UsedCount: null,
        RemainCount: null,
        FreezeCount: null,
        IsOpen: null,
    }
}); };
var getters = {};
var mutations = {
    UPDATEPARAMS: function (state, data) {
        Object.assign(state.accountStatistics, data);
    }
};
var actions = {
    getMainRechargeRecords: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'violation/ViolationQuery/GetMainRechargeRecords',
            data: Data
        });
    },
    getCityRules: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: this.$envConfig().urlConfig.supplierUrl + 'Supply/GetCityRules',
            data: Data,
            requestMethod: 'form'
        });
    }
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
