var state = function () { return ({
    listParams: {
        OID: null,
        Status: '-1',
        RepairType: null,
        BillingState: null,
        StartTime: null,
        EndTime: null,
        KeyValue: ''
    },
    StatusType: [
        {
            name: '维修中',
            value: 1
        },
        {
            name: '已修好',
            value: 2
        },
    ],
    repairType: [
        {
            name: '运营维修',
            value: 1
        },
        {
            name: '资产维护',
            value: 2
        },
        {
            name: '故障维修',
            value: 3
        }
    ]
}); };
var getters = {
    getterParams: function (state) {
        Object.assign(state.listParams, { OID: null });
        return state.listParams;
    }
};
var mutations = {
    UPDATEPARAMS: function (state, data) {
        Object.assign(state.listParams, data);
    }
};
var actions = {
    getList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/VehicleRepair/List",
            data: Data
        });
    },
    add: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/VehicleRepair/Add",
            data: Data
        });
    },
    detail: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/VehicleRepair/Details",
            data: Data
        });
    },
    update: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/VehicleRepair/Update",
            data: Data
        });
    },
    delete: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/VehicleRepair/Delete",
            data: Data
        });
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
