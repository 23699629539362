var state = function () { return ({
    listParams: {
        OID: null,
        Status: null,
        Grade: null,
        Source: '',
        BusinessType: '',
        StartDate: '',
        EndDate: '',
        KeyValue: ''
    },
}); };
var getters = {
    getterParams: function (state) {
        Object.assign(state.listParams, { OID: null });
        return state.listParams;
    }
};
var mutations = {
    UPDATEPARAMS: function (state, data) {
        Object.assign(state.listParams, data);
    }
};
var actions = {
    getList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/BusinessCustomers/GetList",
            data: Data
        });
    },
    add: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/BusinessCustomers/AddBusinessCustomers",
            data: Data
        });
    },
    DetailByEdit: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/BusinessCustomers/DetailByEdit",
            data: Data
        });
    },
    detail: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/BusinessCustomers/GetDetail",
            data: Data
        });
    },
    update: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/BusinessCustomers/EditBusinessCustomers",
            data: Data
        });
    },
    delete: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/BusinessCustomers/DeleteBusinessCustomers",
            data: Data
        });
    },
    GetBusinessSource: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/BusinessCustomers/GetBusinessSource",
            data: Data
        });
    },
    AddBusinessSource: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/BusinessCustomers/AddBusinessSource",
            data: Data
        });
    },
    UpdateBusinessSource: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/BusinessCustomers/UpdateBusinessSource",
            data: Data
        });
    },
    DeleteBusinessSource: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/BusinessCustomers/DeleteBusinessSource",
            data: Data
        });
    },
    follow: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/BusinessFollow/Add",
            data: Data
        });
    },
    CheckBusinessPhone: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/BusinessCustomers/CheckBusinessPhone",
            data: Data
        });
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
