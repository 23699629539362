var state = function () { return ({
    listParams: {
        OID: null,
        KeyValue: null,
        StockBusinessType: null,
        StartDate: '',
        EndDate: '',
        TagStatus: null,
        Status: "-1",
        MID: null,
        Page: 1,
        PerPage: 10
    }
}); };
var mutations = {};
var actions = {
    getList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOuts/GetStockInOutList",
            data: Data
        });
    },
    getDetail: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOuts/GetStockInOutById",
            data: Data
        });
    },
    checkVehicle: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOuts/CheckStockInOut",
            data: Data
        });
    },
    getCustomers: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOuts/GetCustomers",
            data: Data
        });
    },
    getBillsCustomers: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/Bills/GetCustomers",
            data: Data
        });
    },
    getCustomerContracts: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOuts/GetCustomerContracts",
            data: Data
        });
    },
    getContractVehicles: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOuts/GetContractVehicles",
            data: Data
        });
    },
    getOutInWarehouseType: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOutTypeConfig/GetList",
            data: Data
        });
    },
    addOutInWarehouseType: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOutTypeConfig/Create",
            data: Data
        });
    },
    getDamageStatus: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOuts/GetDamageStatus",
            data: Data
        });
    },
    getVehicleParts: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOutCheckConfig/GetChosenStockInOutCheckItems",
            data: Data
        });
    },
    getStockBusinessType: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOuts/GetStockBusinessType",
            data: Data
        });
    },
    getStockStatus: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOuts/GetStockStatus",
            method: "GET"
        });
    },
    contractVelicles: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Vehicle/ContractVelicles',
            data: Data
        });
    },
    addStockInOut: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/StockInOuts/AddStockInOut',
            data: Data
        });
    },
    delStockInOutTypeConfig: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/StockInOutTypeConfig/Del',
            data: Data
        });
    },
    changeStatus: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/StockInOutTypeConfig/ChangeStatus',
            data: Data
        });
    },
    updateTypeConfig: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/StockInOutTypeConfig/Update',
            data: Data
        });
    },
    getStockInOutListByDID: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/StockInOuts/GetStockInOutListByDID',
            data: Data
        });
    },
    getStockOutInCustomers: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/StockInOuts/GetStockOutInCustomers',
            data: Data
        });
    },
    getVehDamageItemsByPickCar: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "saas/StockInOuts/GetVehDamageItemsByPickCar",
            data: Data
        });
    },
    getUserDrivers: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'user/UserDriver/GetUserDrivers',
            data: aData
        });
    },
    getCustomersByOther: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/StockInOuts/GetCustomersByOther',
            data: aData
        });
    },
    getBackVehicles: function (_a, aData) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/StockInOuts/GetBackVehicles',
            data: aData
        });
    }
};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
