var default_1 = (function () {
    function default_1(sideMenu, routePath, mutations) {
        this.crumb = [];
        this.sideMenu = [];
        this.routePath = '';
        this.sideMenu = sideMenu || [];
        this.routePath = routePath;
        this.setBreadCrumb = mutations.SETBREADCRUMB[0];
        this.setActiveMenu = mutations.ACTIVEMENU[0];
        this.setMenus = mutations.SETMENUS[0];
        this.setMenus(this.sideMenu);
        this.generateCrumb();
    }
    default_1.prototype.generateCrumb = function () {
        var _this = this;
        var paths = this.routePath.split('/');
        var len = paths.length;
        var flag = false;
        while (len > 2) {
            this.crumb = [];
            this.fixMenu(paths.slice(0, len).join('/'));
            var cLen = this.crumb.length;
            if (cLen > 0) {
                this.setActiveMenu(this.crumb[cLen - 1].ID);
                break;
            }
            else {
                flag = true;
            }
            len--;
        }
        if (flag) {
            var titleTime_1 = setTimeout(function () {
                _this.crumb.push({ ID: '1', Name: document.title, Url: '' });
                _this.setBreadCrumb(_this.crumb);
                clearTimeout(titleTime_1);
            }, 800);
        }
        else {
            if (this.routePath === '/') {
            }
            else {
                this.setBreadCrumb(this.crumb);
            }
        }
    };
    default_1.prototype.fixMenu = function (val) {
        for (var i = 0, l = this.listMenu.length; i < l; i++) {
            if (this.listMenu[i].Url === val || this.listMenu[i].ID === val) {
                this.crumb.unshift(this.listMenu[i]);
                this.fixMenu(this.listMenu[i].Parent);
                break;
            }
        }
    };
    Object.defineProperty(default_1.prototype, "listMenu", {
        get: function () {
            var _this = this;
            var list = [];
            this.sideMenu.forEach(function (item) {
                list = list.concat(_this.deepMenus(item));
            });
            return list;
        },
        enumerable: true,
        configurable: true
    });
    default_1.prototype.deepMenus = function (menus) {
        var nodes = [];
        if (menus != null) {
            var stack = [];
            stack.push(menus);
            while (stack.length != 0) {
                var item = stack.pop();
                nodes.push(item);
                var childs = item.Childs;
                if (childs != null) {
                    for (var i = childs.length - 1; i >= 0; i--) {
                        stack.push(childs[i]);
                    }
                }
            }
        }
        return nodes;
    };
    return default_1;
}());
export default default_1;
