var state = function () { return ({
    queryParams: {
        Plate: null,
        PlateType: null,
        ModelName: null,
        VIN: null,
        EngineNumber: null,
    },
    breakQueryParams: {
        OID: null,
        BusinessType: null,
        Tags: [],
        KeyValue: null,
        Status: null,
        SelectDay: null
    }
}); };
var getters = {
    getterQueryParams: function (state) {
        Object.assign(state.breakQueryParams, { OID: null });
        return state.breakQueryParams;
    }
};
var mutations = {
    UPDATEQUERYPARAMS: function (state, data) {
        Object.assign(state.breakQueryParams, data);
    }
};
var actions = {
    querySingle: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'violation/ViolationQuery/QuerySingle',
            data: Data
        });
    },
    queryList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'violation/ViolationQuery/QueryList',
            data: Data
        });
    },
    queryListAll: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'violation/ViolationQuery/QueryListAll',
            data: Data
        });
    },
    queryImport: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'violation/ViolationQuery/QueryImport',
            data: Data
        });
    },
    getImportErrorFile: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'violation/ViolationQuery/GetImportErrorFile',
            data: Data
        });
    },
    queryImportOrder: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'violation/ViolationQuery/QueryImportOrder',
            data: Data
        });
    },
    getViolationVehicles: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: 'saas/Vehicle/GetViolationVehicles',
            data: Data
        });
    }
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
