import dataCenter from "~/store/modules/reports/data-center";
import riskReport from "~/store/modules/reports/risk-report";
var state = function () { return ({}); };
var mutations = {};
var actions = {};
var getters = {};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {
        dataCenter: dataCenter,
        riskReport: riskReport
    }
};
