var state = function () { return ({
    listParams: {
        OID: null,
        InsuranceType: null,
        InsuranceStatus: '1',
        StartTime: null,
        EndTime: null,
        KeyValue: null,
    },
    listRecordParams: {
        OID: null,
        InsuranceType: null,
        CloseStatus: null,
        InsuredType: null,
        StartTime: null,
        EndTime: null,
        KeyValue: ''
    },
    listHistoryParams: {
        VID: null,
    },
    StatusType: [
        {
            name: '在保',
            value: 1
        },
        {
            name: '将脱保',
            value: 2
        },
        {
            name: '脱保',
            value: 3
        },
        {
            name: '未投保',
            value: 4
        },
    ],
    transactorType: [
        {
            name: '客户',
            value: 1
        },
        {
            name: '公司',
            value: 2
        },
    ],
    guaranteeType: [
        {
            name: '交强险',
            value: 1
        },
        {
            name: '商业险',
            value: 2
        },
        {
            name: '承运险',
            value: 3
        },
    ],
}); };
var getters = {
    getterParams: function (state) {
        Object.assign(state.listParams, { OID: null });
        return state.listParams;
    },
    getterRecordParams: function (state) {
        Object.assign(state.listRecordParams, { OID: null });
        return state.listRecordParams;
    },
};
var mutations = {
    UPDATEPARAMS: function (state, data) {
        Object.assign(state.listParams, data);
    },
    UPDATERECORDPARAMS: function (state, data) {
        Object.assign(state.listRecordParams, data);
    },
};
var actions = {
    getList: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Insurances/GetVehicleInsurancesList",
            data: Data
        });
    },
    record: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Insurances/GetInsurancesRecordList",
            data: Data
        });
    },
    add: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Insurances/Add",
            data: Data
        });
    },
    update: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Insurances/Editor",
            data: Data
        });
    },
    delete: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Insurances/Delete",
            data: Data
        });
    },
    detail: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Insurances/GetInsurancesRecordDetails",
            data: Data
        });
    },
    getInsurancesRecordByVID: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Insurances/GetInsurancesRecordByVID",
            data: Data
        });
    },
    CheckInsuranceNo: function (_a, Data) {
        var commit = _a.commit, state = _a.state;
        return this.$apiAjax({
            apiName: "traffic/Insurances/CheckInsuranceNo",
            data: Data
        });
    },
};
export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
};
